import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  CardContent,
  Grid,
  Typography,
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  styled,
} from '@mui/material';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import Dropzone from 'react-dropzone';
import { CloudUpload, ExpandMore, Info, SaveAlt } from '@mui/icons-material';
import Table from '../../../components/common/Table';
import { getOfficeList } from '../../../services/admin/users';
import { CSVLink } from 'react-csv';
import { createOrderFromCSV } from '../../../services/order/order';
import { sampleDataSetCSV } from '../../../utils/constants';
import { snakeToTitleCase } from '../../../utils/helpers';

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  backgroundColor: '#ecf3f9a3',
  // flexDirection: 'row-reverse',

  '& .MuiAccordionSummary-content': {
    // marginLeft: theme.spacing(1),
    fontSize: '13px',
    fontWeight: '500',
  },
}));

const BulkUpload = () => {
  const navigate = useNavigate();
  const csvExport = useRef();
  const { order } = useSelector((state) => state?.authSlice);
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [officeList, setOfficeList] = useState([]);
  const [skippedRows, setSkippedRows] = useState([]);
  const [errors, setErrors] = useState([]);
  const [file, setFile] = useState(null);
  const [url, setURL] = useState(null);

  useEffect(() => {
    if (file) {
      setURL(URL.createObjectURL(file));
    }
  }, [file]);

  useEffect(() => {
    fetchOfficeList();
  }, []);

  const fetchOfficeList = async () => {
    setLoading(true);
    const res = await getOfficeList(order?.token, order?.client_id, order?.id);
    if (res?.success && res?.response?.length > 1) {
      setOfficeList(res?.response?.map((ofc) => ofc.office_name) || []);
    }
    setLoading(false);
  };
  const downloadCSV = () => {
    let tempData = sampleDataSetCSV(officeList);
    if (!officeList?.length) {
      for (const record of tempData) {
        delete record.Office;
      }
    }
    setCsvData(tempData);
    setTimeout(() => {
      csvExport.current.link.click();
    }, 1000);
  };

  const validateAndCreateOrders = async (fileObj, skip = false) => {
    setLoading(true);
    setFile(fileObj);
    setErrors([]);
    const form = new FormData();
    form.append('order_file', fileObj);
    form.append('client_id', order?.client_id);
    form.append('skip_on_error', skip);
    if (skip) {
      form.append('skip_rows', skippedRows);
    }
    setSkippedRows([]);
    const res = await createOrderFromCSV(
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      order?.token
    );
    if (res?.success) {
      toast.success(res?.message);
      setTimeout(() => {
        setLoading(false);
        navigate(-1);
      }, 3000);
    } else {
      if (typeof res?.message === 'object') {
        setSkippedRows(res?.message?.skip_rows);
        let errorData = [];
        for (let key of Object.keys(res?.message || {})) {
          if (key === 'skip_rows') continue;
          errorData.push({ title: key, errors: res?.message?.[key] });
        }
        setErrors(errorData);
        toast.error('There are some errors in your CSV. Details will be displayed on the page.');
      } else {
        toast.error(res?.message);
      }

      setLoading(false);
    }
  };

  let columns = [
    {
      label: 'Column Name',
      id: 'column_name',
    },
    {
      label: 'Possible Values',
      id: 'possible_values',
    },
    {
      label: 'Example',
      id: 'example',
    },
    {
      label: 'Required',
      id: 'required',
    },
  ];

  let tableData = [
    {
      column_name: 'Office Name',
      possible_values: officeList?.length
        ? `Enter a value from ${officeList?.map((ofc) => `"${ofc}"`)?.join(' or ')}.`
        : `Your client has only one office you can keep this field as blank we will manage it internally.`,
      required: 'Required',
      example: '',
      example: officeList?.[0] ?? '-',
    },
    {
      column_name: 'Order Type',
      possible_values: `Enter either "refinance" or "purchase".`,
      required: 'Required',
      example: '',
      example: 'purchase',
    },
    {
      column_name: 'Escrow Number',
      possible_values: `Enter a unique string for each order.`,
      required: 'Required',
      example: '',
      example: 'VSS-21231231',
    },
    {
      column_name: 'Anticipated Close Date',
      possible_values: `Enter a date in the "MM/DD/YYYY" format.`,
      required: 'Required',
      example: '',
      example: '09/31/2024',
    },
    {
      column_name: 'Property Address Line 1',
      possible_values: 'Enter the street number and route of the property address.',
      required: 'Required',
      example: '18721 Paseo Picasso',
    },
    {
      column_name: 'Property Address Line 2',
      possible_values: 'Enter other details for your property address.',
      required: 'Required',
      example: 'Near Central Park',
    },
    {
      column_name: 'Property Unit Number',
      possible_values: 'Enter the unit number for the property address, if applicable.',
      required: 'Optional',
      example: 'A',
    },
    {
      column_name: 'Property City',
      possible_values: 'Enter the city for the property address.',
      required: 'Required',
      example: 'Irvine',
    },
    {
      column_name: 'Property State',
      possible_values: 'Enter the state for the property address.',
      required: 'Required',
      example: 'CA',
    },
    {
      column_name: 'Property ZIP Code',
      possible_values: 'Enter the ZIP code for the property address.',
      required: 'Required',
      example: '92603',
    },
    {
      column_name: 'Property County',
      possible_values: 'Enter the county for the property address.',
      required: 'Required',
      example: 'Orange',
    },
    {
      column_name: 'Number of participant',
      possible_values:
        'Enter the number of participants for the transaction. For example, if only the seller is included, it should be 1. If both the buyer and seller are included, it should be 2.',
      required: 'Required',
      example: '1',
    },
    {
      column_name: 'Participant1 Category',
      possible_values: `Enter value either "individual" or "entity".`,
      required: 'Required',
      example: 'Individual',
    },
    {
      column_name: 'Participant1 Type',
      possible_values: `Enter value from "seller" or "buyer" or "borrower".`,
      required: 'Required',
      example: 'seller',
    },
    {
      column_name: 'Participant1 First Name',
      possible_values: 'Enter a first name.',
      required: 'If participant category is "Individual", then Yes; otherwise No',
      example: 'John',
    },
    {
      column_name: 'Participant1 Last Name',
      possible_values: 'Enter a last name.',
      required: 'If participant category is "Individual", then Yes; otherwise No',
      example: 'Doe',
    },
    {
      column_name: 'Participant1 Social Security Number',
      possible_values:
        'Enter a social security number (Only applicable if participant category is "Individual").',
      required: 'Optional',
      example: '111-11-1111',
    },
    {
      column_name: 'Participant1 Email',
      possible_values: 'Enter a valid email address.',
      required: 'Required',
      example: 'john.doe@gmail.com',
    },
    {
      column_name: 'Participant1 Phone',
      possible_values: 'Enter a valid phone number.',
      required: 'Optional',
      example: '(111) 111-2222',
    },
    {
      column_name: 'Participant1 Legal Business Name',
      possible_values:
        'Enter a legal business name (Only applicable if participant category is "Entity").',
      required: 'If participant category is "Entity", then Yes; otherwise No',
      example: 'Tech Corporation',
    },
    {
      column_name: 'Participant1 Entity Type',
      possible_values:
        'Enter from "Corporation", "LLC", "Trust", "Partnership", or "Other" (Only applicable if participant category is "Entity").',
      required: 'Optional',
      example: 'Corporation',
    },
    {
      column_name: 'Participant1 Entity State',
      possible_values:
        'Enter the state abbreviation for the entity (Only applicable if participant category is "Entity").',
      required: 'Optional',
      example: 'CA',
    },
    {
      column_name: 'Participant1 Entity EIN',
      possible_values:
        'Enter an Employer Identification Number (Only applicable if participant category is "Entity").',
      required: 'Optional',
      example: '11-1111111',
    },
    {
      column_name: 'Participant1 mailing address same as property address',
      possible_values:
        'Enter value either "yes" or "no"(If this is selected as "Yes" you can skip adding participant mailing address details).',
      required: 'Required',
      example: 'yes',
    },
    {
      column_name: 'Participant1 mailing Address line 1',
      possible_values: 'Enter the street number and route for the participant’s address.',
      required: 'Required',
      example: '18721 Paseo Picasso',
    },
    {
      column_name: 'Participant1 mailing Address line 2',
      possible_values: 'Mention other details about the address.',
      required: 'Optional',
      example: 'Near Central Park',
    },
    {
      column_name: 'Participant1 mailing Unit Number',
      possible_values: 'Enter the unit number for the participant’s address.',
      required: 'Optional',
      example: 'A',
    },
    {
      column_name: 'Participant1 mailing City',
      possible_values: 'Enter the city for the participant’s address.',
      required: 'Required',
      example: 'Irvine',
    },
    {
      column_name: 'Participant1 mailing State',
      possible_values: 'Enter the state for the participant’s address.',
      required: 'Required',
      example: 'CA',
    },
    {
      column_name: 'Participant1 mailing ZIP Code',
      possible_values: 'Enter the ZIP code for the participant’s address.',
      required: 'Required',
      example: '92603',
    },
    {
      column_name: 'Participant1 mailing County',
      possible_values: 'Enter the county for the participant’s address.',
      required: 'Optional',
      example: 'Orange',
    },
    {
      column_name: 'Participant2 Category',
      possible_values: `Enter value either "Individual" or "Entity".`,
      required: 'Yes, in cases where the order has multiple participants.',
      example: 'Entity',
    },
    {
      column_name: 'Participant2 Type',
      possible_values: `Enter value from "seller" or "buyer" or "borrower".`,
      required: 'Yes, in cases where the order has multiple participants.',
      example: 'buyer',
    },
    {
      column_name: 'Participant2 First Name',
      possible_values: 'Enter a first name.',
      required:
        'If participant category is "Individual" and in cases where the order has multiple participants, then Yes; otherwise No',
      example: 'James',
    },
    {
      column_name: 'Participant2 Last Name',
      possible_values: 'Enter a last name.',
      required:
        'If participant category is "Individual" and in cases where the order has multiple participants, then Yes; otherwise No',
      example: 'Smith',
    },
    {
      column_name: 'Participant2 Social Security Number',
      possible_values:
        'Enter a social security number (Only applicable if participant category is "Individual").',
      required: 'Optional',
      example: '222-22-2222',
    },
    {
      column_name: 'Participant2 Email',
      possible_values: 'Enter a valid email address.',
      required: 'Yes, in cases where the order has multiple participants.',
      example: 'james.smith@gmail.com',
    },
    {
      column_name: 'Participant2 Phone',
      possible_values: 'Enter a valid phone number.',
      required: 'Optional',
      example: '(222) 222-2222',
    },
    {
      column_name: 'Participant2 Legal Business Name',
      possible_values:
        'Enter a legal business name (Only applicable if participant category is "Entity").',
      required:
        'If participant category is "Entity" and in cases where the order has multiple participants, then Yes; otherwise No',
      example: 'Tech LLC',
    },
    {
      column_name: 'Participant2 Entity Type',
      possible_values:
        'Enter from "Corporation", "LLC", "Trust", "Partnership", or "Other" (Only applicable if participant category is "Entity").',
      required: 'Optional',
      example: 'LLC',
    },
    {
      column_name: 'Participant2 Entity State',
      possible_values:
        'Enter the state abbreviation for the entity (Only applicable if participant category is "Entity").',
      required: 'Optional',
      example: 'AL',
    },
    {
      column_name: 'Participant2 Entity EIN',
      possible_values:
        'Enter an Employer Identification Number (Only applicable if participant category is "Entity").',
      required: 'Optional',
      example: '22-2222222',
    },
    {
      column_name: 'Participant2 mailing address same as property address',
      possible_values:
        'Enter value either "yes" or "no"(If this is selected as "yes" you can skip adding participant mailing address details).',
      required: 'Yes, in cases where the order has multiple participants.',
      example: 'no',
    },
    {
      column_name: 'Participant2 mailing Address line 1',
      possible_values: 'Enter the street number and route for the participant’s address.',
      required: 'Yes, in cases where the order has multiple participants.',
      example: '18721 Paseo Picasso',
    },
    {
      column_name: 'Participant2 mailing Address line 2',
      possible_values: 'Mention other details about the address.',
      required: 'Optional',
      example: 'Near Central Park',
    },
    {
      column_name: 'Participant2 mailing Unit Number',
      possible_values: 'Enter the unit number for the participant’s address.',
      required: 'Optional',
      example: 'A',
    },
    {
      column_name: 'Participant2 mailing City',
      possible_values: 'Enter the city for the participant’s address.',
      required: 'Yes, in cases where the order has multiple participants.',
      example: 'Irvine',
    },
    {
      column_name: 'Participant2 mailing State',
      possible_values: 'Enter the state for the participant’s address.',
      required: 'Yes, in cases where the order has multiple participants.',
      example: 'CA',
    },
    {
      column_name: 'Participant2 mailing ZIP Code',
      possible_values: 'Enter the ZIP code for the participant’s address.',
      required: 'Yes, in cases where the order has multiple participants.',
      example: '92603',
    },
    {
      column_name: 'Participant2 mailing County',
      possible_values: 'Enter the county for the participant’s address.',
      required: 'Optional',
      example: 'Orange',
    },
  ];

  if (!officeList?.length) {
    tableData = tableData.filter((rec) => rec.column_name !== 'Office Name');
  }

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12} sm={12} xs={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid item md={12} className='d-flex justify-between align-center title-bar'>
                <Typography variant='h4' className='mb-0'>
                  Bulk Order Upload
                </Typography>
              </Grid>

              <Grid item md={12} className='d-flex justify-center align-center mt-16'>
                {/* <Button
                  autoFocus
                  onClick={() => {
                    downloadCSV();
                  }}
                  variant='contained'
                  color='primary'
                  style={{ padding: '4px 12px' }}
                >
                  Download Sample CSV
                </Button> */}
                <div>
                  <CSVLink
                    data={csvData}
                    filename={'bulk_upload_sample.csv'}
                    ref={csvExport}
                    style={{ display: 'none' }}
                  ></CSVLink>
                  <Button
                    variant='contained'
                    onClick={downloadCSV}
                    className={'add-btn'}
                    hideAddIcon
                  >
                    <SaveAlt style={{ marginRight: '8px', fontSize: '18px' }} />
                    {'Download Sample CSV'}
                  </Button>
                </div>
              </Grid>
              <Grid item md={12} className='d-flex flex-column align-center mt-16'>
                <Dropzone
                  onDrop={(acceptedFiles) => validateAndCreateOrders(acceptedFiles[0], false)}
                  accept={{
                    'text/csv': ['.csv'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
                      '.xls',
                      '.xlsx',
                    ],
                  }}
                  multiple={false}
                >
                  {({ getRootProps, getInputProps, isDragActive, isFileDialogActive }) => (
                    <div
                      className={
                        isDragActive || isFileDialogActive ? 'drag-active dropzone' : 'dropzone'
                      }
                      style={{ padding: '20px 100px' }}
                    >
                      <div
                        className='dz-message d-flex flex-column justify-center'
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} name='file' />
                        <i className='h2 d-inline-block'>
                          <CloudUpload className='ms-2' fontSize={'medium'} />
                        </i>
                        <p className='mb-0 mt-0' style={{ fontWeight: '550', fontSize: '13px' }}>
                          Click or drag file to this area to upload
                        </p>
                        <p className='mb-0 mt-4 color-g f-12'>
                          Only single file is allowed to be uploaded and Only CSV or Excel will be
                          accepted.
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
                {file && (
                  <div
                    className='f-13-5 color-p mt-8 cursor-pointer'
                    onClick={() => {
                      if (url) {
                        window.open(url, '_blank');
                      }
                    }}
                  >
                    {file?.name}
                  </div>
                )}
              </Grid>
              {errors?.length ? (
                <Grid item md={12} className='mt-8'>
                  <Alert severity='warning' className='f-13 fw-500'>
                    There are some errors in your uploaded CSV. Please fix the issues mentioned
                    below and re-upload the CSV, or click the 'Skip and Create Orders' button below
                    to skip the erroneous records and create orders for the remaining valid records.
                    <div className='mt-16'>
                      {errors?.map((err, index) => {
                        return (
                          <Accordion defaultExpanded={index === 0}>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls='panel1-content'
                              id='panel1-header'
                            >
                              <div className='fw-550 f-13' style={{ textTransform: 'capitalize' }}>
                                {err?.title}
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              {Object.keys(err.errors || {}).map((errKey, idx) => {
                                return (
                                  <Grid container>
                                    <Grid
                                      item
                                      md={3}
                                      className='f-13 fw-500 p-8 d-flex align-items-center color-p'
                                      border={'1px solid rgba(50, 50, 93, 0.25)'}
                                      borderRight={'0px'}
                                      borderTop={
                                        idx === 0 ? '1px solid rgba(50, 50, 93, 0.25)' : '0px'
                                      }
                                    >
                                      {snakeToTitleCase(errKey)}
                                    </Grid>
                                    <Grid
                                      item
                                      md={9}
                                      sm={9}
                                      xs={9}
                                      className='fw-450 d-flex justify-start f-13 p-8'
                                      border={'1px solid rgba(50, 50, 93, 0.25)'}
                                      borderTop={
                                        idx === 0 ? '1px solid rgba(50, 50, 93, 0.25)' : '0px'
                                      }
                                    >
                                      {err?.errors?.[errKey]?.map((errMessage) => (
                                        <div>{errMessage}</div>
                                      ))}
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </div>
                    <div className='d-flex justify-end align-center mt-8'>
                      <Button
                        onClick={() => {
                          validateAndCreateOrders(file, true);
                        }}
                      >
                        Skip and Create Orders
                      </Button>
                    </div>
                  </Alert>
                </Grid>
              ) : (
                ''
              )}
              <Grid item md={12} className='d-flex justify-start align-center mt-8'>
                {' '}
                <div className='permission-info' style={{ marginTop: '16px' }}>
                  <div className='title color-p'>
                    <Info style={{ fontSize: '18px' }} /> <p className='title-text'>Notes:</p>
                  </div>
                  <div className='mt-8 f-13-5 fw-500 color-g'>
                    Refer to the table below for details on the allowed column values in the CSV
                    file. Please note that all values are case-sensitive.
                  </div>
                </div>
              </Grid>
              <Grid item md={12} className='mt-16'>
                <Table loader={loading} columns={columns} data={tableData} hidePagination={true} />
              </Grid>
            </CardContent>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BulkUpload;
