import React, { useEffect, useState } from 'react';
import {
  CssBaseline,
  IconButton,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Avatar,
  Chip,
} from '@mui/material';
import {
  FormatIndentDecrease,
  FormatIndentIncrease,
  Person,
  ShoppingCart,
} from '@mui/icons-material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Logo from '../../../assets/images/Voxtur-verify.png';
import { configuration, logout } from '../../../store/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Breadcrumb from '../../common/Breadcrumb';
import { resetBreadcrumb } from '../../../store/slices/breadcrumbSlice';
import { useNavigate } from 'react-router';
import Loader from '../../common/Loader';
import { getConfigurations, orderLogout } from '../../../services/auth/auth';
import ProfileDrawer from '../../common/ProfileDrawer';
import chroma from 'chroma-js';

const OrderMainLayout = ({ children }) => {
  const url = window.location.origin;
  const { order } = useSelector((state) => state?.authSlice);
  const config = useSelector((state) => state.authSlice)?.configuration?.[url];
  const user = order?.first_name + ' ' + order?.last_name;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (Object.keys(config?.branding_json || {})?.length) {
      document.documentElement.style.setProperty(
        '--primary-color',
        config?.branding_json?.primary_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--sidebar-color',
        config?.branding_json?.sidebar_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--sidebar-font-color',
        config?.branding_json?.sidebar_font_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--sidebar-font-darker-color',
        config?.branding_json?.sidebar_font_color
          ? chroma(config?.branding_json?.sidebar_font_color).darken(0.5).hex()
          : ''
      );
      document.documentElement.style.setProperty(
        '--sidebar-lighten-color',
        config?.branding_json?.sidebar_color
          ? chroma(config?.branding_json?.sidebar_color).brighten(0.5).hex()
          : ''
      );
      document.documentElement.style.setProperty(
        '--header-color',
        config?.branding_json?.header_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--header-font-color',
        config?.branding_json?.header_font_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--bg-color',
        config?.branding_json?.background_color ?? ''
      );
    }
  }, [config]);

  useEffect(() => {
    getConfigurationDetails();
  }, []);

  const getConfigurationDetails = async () => {
    setLoading(true);
    let body = { domain: url, office_name: config?.office_name?.replace(/ /g, '') };
    const res = await getConfigurations(body);
    if (res.success) {
      dispatch(
        configuration({
          url,
          data: { ...res?.response?.[0] },
        })
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setOpen(false);
    }
  }, [window.innerWidth]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const drawerWidth = 240;
  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `${window.innerWidth < 768 ? '0px' : `calc(${theme.spacing(5)}})`}`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(6)})`,
    },
  });
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    })
  );
  const handleLogout = async () => {
    setLoading(true);
    try {
      const resData = await orderLogout(order?.token);
      if (resData?.success) {
        dispatch(
          logout({
            code: 'order',
          })
        );
        dispatch(resetBreadcrumb());
        navigate('/login');
      } else {
        toast.error(resData?.error || resData?.message || 'Something went wrong!');
      }
    } catch (error) {
      toast.error('Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const menuItems = [
    {
      label: 'Orders',
      icon: <ShoppingCart fontSize='small' />,
      route: '/orders/order',
    },
  ];

  const handleToggle = () => {
    setOpen((open) => !open);
  };

  return (
    <>
      {loading && <Loader />}
      <CssBaseline />
      <div style={{ display: 'flex' }}>
        <Drawer
          variant='permanent'
          open={open}
          className='order-dashboard-sidebar'
          style={{
            background: config?.branding_json?.sidebar_color
              ? config?.branding_json?.sidebar_color
              : '#fff',
          }}
        >
          <DrawerHeader>
            {open === true && (
              <img
                src={config?.company_logo_url ? config?.company_logo_url : Logo}
                alt='logo'
                width={130}
              />
            )}
          </DrawerHeader>
          <Divider />
          <div className={`${open ? 'list-item-wrapper' : ''}`}>
            {/* <div>
            <button
              className='order-btn'
              onClick={() => {
                navigate('/orders/add');
              }}
            >
              + New Order
            </button>
          </div> */}
            {menuItems.map((item, index) => (
              <ListItem
                key={item.label}
                button
                onClick={() => navigate(item.route)}
                // className={`${
                //   item.route?.split('/')?.[2] === location?.pathname?.split('/')?.[2]
                //     ? 'selected-item'
                //     : ''
                // } sidebar-item`}
                className='sidebar-item selected-item'
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}

            {/* <ListItem button onClick={handleLogout} className='sidebar-item last-item'>
              <ListItemIcon>
                <ExitToAppIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText primary='Logout' />
            </ListItem> */}
          </div>
        </Drawer>
        <div style={{ flexGrow: 1, overflow: 'auto' }}>
          <Toolbar className='header-title'>
            <div className='d-flex align-items-center'>
              <IconButton onClick={handleToggle} className='mr-4'>
                {open === false ? <FormatIndentIncrease /> : <FormatIndentDecrease />}
              </IconButton>
              {window.innerWidth > 550 && <Breadcrumb />}
            </div>
            <div className='d-flex flex-row'>
              <div className='profile-chip'>
                <Chip
                  label={`Client : ${order?.company_name}`}
                  variant='outlined'
                  color='primary'
                  className='mr-16'
                />
                <Chip
                  label={`Office : ${
                    order?.offices?.length > 1 ? 'All Offices' : order?.offices?.[0]?.office_name
                  }`}
                  variant='outlined'
                  className='mr-16'
                  color='primary'
                />
              </div>
              <div className='profile-chip user-name'>
                <Chip
                  onClick={handleDrawerOpen}
                  avatar={
                    <Avatar
                      sx={{ width: '34px', height: '34px', fontSize: '16px' }}
                      className='mr-4 cursor-pointer bg-p'
                    >
                      <Person style={{ color: 'white' }} />
                    </Avatar>
                  }
                  label={user}
                  variant='outlined'
                  className='cursor-pointer'
                  color='primary'
                />
              </div>
            </div>
          </Toolbar>
          {children}
        </div>
        <ProfileDrawer open={drawerOpen} onClose={handleDrawerClose} type='order' />
      </div>
    </>
  );
};

export default OrderMainLayout;
