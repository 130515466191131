import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { MenuItem, Select, Switch, TextField, IconButton, Button } from '@mui/material';
import { DocElementType } from '../../../utils/constants';
import ManageOptionsDrawer from './ManageOptionsDrawer';
import TuneIcon from '@mui/icons-material/Tune';
import _, { cloneDeep } from 'lodash';
import { arrayMoveImmutable } from 'array-move';
import { randomUUID } from '../../../utils/helpers';
import FormPreview from '../../../pages/admin/templates/FormPreview';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteModal from '../../common/DeleteModal';
import { toast } from 'react-toastify';
import SettingsIcon from '@mui/icons-material/Settings';
import FieldConfigurationDrawer from './FieldConfigurationDrawer';
import StaticPaginationTable from '../../common/StaticPaginationTable';

const CreateQuestionsForm = forwardRef((props, ref) => {
  const prefix = props?.templateDetails?.participant_type_name?.replace(' ', '_')?.toLowerCase();
  const [allElements, setAllElements] = useState([]);
  const [formElements, setFormElements] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isOptionDrawerVisible, setOptionsDrawerVisible] = useState(false);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [isConfigDrawerOpen, setConfigDrawerOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [id, setId] = useState('');

  useImperativeHandle(ref, () => ({
    convertToElementData,
    validateCurrentElements,
  }));

  useEffect(() => {
    setPage(0);
  }, [props.currentSection, props.currentGroup]);

  const columns = [
    { id: 'elementName', label: 'Element Name', type: 'jsx' },
    { id: 'labelField', label: 'Label', type: 'jsx' },
    { id: 'elementType', label: 'Element Type', type: 'jsx' },
    { id: 'parentElement', label: 'Parent Element', type: 'jsx' },
    { id: 'parentValue', label: 'Parent Value', type: 'jsx' },
    { id: 'requiredField', label: 'Required', type: 'jsx' },
    { id: 'displayField', label: 'Display', type: 'jsx' },
    { id: 'privateField', label: 'Private', type: 'jsx' },
    { id: 'optionsList', label: 'Options', type: 'jsx' },
    { id: 'actions', label: 'Actions', type: 'jsx' },
  ];

  useEffect(() => {
    if (props?.data?.length) {
      fetchAllElements();
    } else {
      setAllElements([]);
      setFormElements([]);
    }
  }, [props?.data]);

  useEffect(() => {
    createFormElements();
  }, [allElements]);

  const fetchAllElements = async () => {
    let elementsData = cloneDeep(props.data);
    let tempElements = [];
    const answer = async (element, parent_element = '', parent_value = '') => {
      tempElements.push({ ...element, parent_element, parent_value });
      if (element.options.length) {
        for (const option of element.options) {
          for (const subElement of option.elements) {
            await answer(subElement, element.element_id, option.option_id);
          }
        }
      }
    };
    for (const element of elementsData) {
      await answer(element);
    }
    setAllElements(tempElements);
  };

  const convertToElementData = () => {
    const elementMap = new Map();

    // Create a map of elements using their IDs for efficient lookup
    for (const element of allElements) {
      if (element?.options?.length) element?.options?.forEach((option) => (option.elements = []));
      elementMap.set(element.element_id, element);
    }

    const elementData = [];

    for (const element of allElements) {
      const parentElementId = element.parent_element;

      if (!parentElementId) {
        delete element.parent_element;
        delete element.parent_value;
        elementData.push(element);
      } else {
        const parentElement = elementMap.get(parentElementId);
        if (parentElement) {
          let foundedOption = parentElement.options.find(
            (option) => option.option_id === element.parent_value
          );
          delete element.parent_element;
          delete element.parent_value;

          if (foundedOption?.elements?.length) {
            foundedOption?.elements?.push(element);
          } else {
            foundedOption.elements = [element];
          }
        }
      }
    }
    props.setData((data) => {
      let tempData = data;
      if (
        tempData?.sections?.[props.currentSection]?.groups?.length &&
        tempData.sections[props.currentSection].groups[props.currentGroup]
      )
        tempData.sections[props.currentSection].groups[props.currentGroup].elements = elementData;
      return tempData;
    });
  };

  const handleChange = (field, value, index) => {
    setAllElements((prevFormElements) => {
      const tempElements = [...prevFormElements];
      tempElements[index][field] = value;
      return tempElements;
    });
  };

  const validateCurrentElements = () => {
    let isValidated = true;
    for (const element of allElements) {
      if (!element?.label?.length && element.element_type !== DocElementType.Media) {
        isValidated = false;
        break;
      }
    }
    return isValidated;
  };

  const handleElementTypeChange = (value, index) => {
    setAllElements((prevFormElements) => {
      let tempElements = [...prevFormElements];

      if (
        [DocElementType.RadioButton, DocElementType.SelectBox, DocElementType.SSelectBox].includes(
          tempElements[index].element_type
        ) &&
        ![DocElementType.RadioButton, DocElementType.SelectBox, DocElementType.SSelectBox].includes(
          value
        )
      ) {
        tempElements[index].options = [];
        tempElements.forEach((element, idx) => {
          if (element?.parent_element === tempElements[index].element_id) {
            tempElements[idx].parent_element = null;
            tempElements[idx].parent_value = null;
          }
        });
        tempElements[index]['element_type'] = value;
        // tempElements = sortElements(tempElements);
      } else if (
        [DocElementType.RadioButton, DocElementType.SelectBox, DocElementType.SSelectBox].includes(
          value
        )
      ) {
        tempElements[index]['element_type'] = value;
        if (!tempElements[index]['options']?.length) {
          tempElements[index]['options'] = [
            {
              elements: [],
              option_id: randomUUID(),
              option_name: 'Default Option',
            },
          ];
        }
      } else {
        tempElements[index]['element_type'] = value;
      }
      if ([DocElementType.Media, DocElementType.MediaLink].includes(value)) {
        tempElements[index]['options'] = [
          {
            elements: [],
            option_id: randomUUID(),
            option_name: 'www.test.com',
          },
        ];
      }
      return tempElements;
    });
  };

  const handleParentTypeChange = (value, index) => {
    setAllElements((prevFormElements) => {
      let tempElements = [...prevFormElements];
      const parentElement = prevFormElements.find((elem) => elem.element_id === value);
      if (parentElement) {
        tempElements[index]['parent_element'] = value;
        tempElements[index]['parent_value'] = parentElement.options[0].option_id || '';
        // tempElements = sortElements(tempElements);
      }
      return tempElements;
    });
  };

  const createFormElements = async () => {
    let tempFormElements = allElements.map((element, index) => {
      return {
        ...element,
        elementName: (props) => <div style={{ width: '200px' }}>{element.element_name}</div>,
        labelField: (props) => (
          <>
            <TextField
              id='outlined-basic'
              key={props.element_id}
              defaultValue={props?.label ? props.label : ''}
              // value={props?.label ? props.label : ''}
              onChange={(e) => {
                handleChange('label', e.target.value, index);
              }}
              className='full-width'
              required={true}
              variant='outlined'
              inputProps={{
                role: 'presentation',
                autoComplete: 'off',
              }}
              style={{ width: '250px', backgroundColor: 'white', background: 'white' }}
            />
            {!props?.label?.length && element.element_type !== DocElementType.Media && (
              <span className='error mb-8 mt-4' style={{ display: 'inherit', fontSize: '12px' }}>
                Please enter label for the field.
              </span>
            )}
          </>
        ),
        elementType: (props) => (
          <Select
            key={props.element_id}
            className='full-width'
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={props.element_type || ''}
            onChange={(e) => {
              handleElementTypeChange(e.target.value, index);
            }}
            style={{ width: '180px' }}
          >
            {Object.keys(DocElementType).map((option, idx) => {
              return (
                <MenuItem value={DocElementType[option]} key={idx}>
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        ),
        parentElement: (props) => {
          let nestedChildren = [];
          let parentElm = '';
          let options = allElements?.filter((option) => {
            if (option.parent_element == props.element_id) {
              parentElm = option.element_id;
              nestedChildren.push(option.element_id);
            }
            return (
              [
                DocElementType.RadioButton,
                DocElementType.SelectBox,
                DocElementType.SSelectBox,
              ].includes(option.element_type) && option.element_name !== props.element_name
            );
          });
          function findChildren(array, parent_element) {
            let children = [];

            // Iterate through the array to find children of the given parent ID
            array.forEach((obj) => {
              if (obj.parent_element === parent_element) {
                children.push(obj.element_id);
                // Recursively find children of this child
                children = children.concat(findChildren(array, obj.element_id));
              }
            });

            return children;
          }
          if (parentElm?.length) {
            let deeplyNestedElems = findChildren(options, parentElm);
            nestedChildren = [...nestedChildren, ...deeplyNestedElems];
          }
          options = options.filter((option) => !nestedChildren.includes(option.element_id));
          return (
            <Select
              key={props.element_id}
              className='full-width'
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={props.parent_element || ''}
              style={{ width: '250px' }}
              disabled={!options?.length}
              onChange={(e) => {
                handleParentTypeChange(e.target.value, index);
              }}
            >
              {options.map((option, idx) => {
                return (
                  <MenuItem value={option.element_id} key={idx}>
                    {option.element_name}
                  </MenuItem>
                );
              })}
            </Select>
          );
        },
        parentValue: (props) => {
          const parentElement = allElements.find(
            (option) => option.element_id == props.parent_element
          );

          return (
            <Select
              key={props.element_id}
              className='full-width'
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={props.parent_value || ''}
              disabled={!parentElement?.options?.length}
              style={{ width: '250px' }}
              onChange={(e) => {
                handleChange('parent_value', e.target.value, index);
              }}
            >
              {parentElement?.options?.map((option, idx) => {
                return (
                  <MenuItem value={option.option_id} key={idx}>
                    {option.option_name}
                  </MenuItem>
                );
              })}
            </Select>
          );
        },
        requiredField: (props) => (
          <Switch
            key={props.element_id}
            checked={props.required}
            size='small'
            onChange={(e) => {
              handleChange('required', e.target.checked, index);
            }}
          />
        ),
        displayField: (props) => (
          <Switch
            key={props.element_id}
            checked={props.display}
            size='small'
            onChange={(e) => {
              handleChange('display', e.target.checked, index);
            }}
          />
        ),
        privateField: (props) => (
          <Switch
            key={props.element_id}
            checked={props.is_private}
            size='small'
            onChange={(e) => {
              handleChange('is_private', e.target.checked, index);
            }}
          />
        ),
        optionsList: (props) => (
          <IconButton
            className='icon-btn'
            disabled={
              ![
                DocElementType.RadioButton,
                DocElementType.SelectBox,
                DocElementType.SSelectBox,
                DocElementType.Media,
              ].includes(props.element_type)
            }
            onClick={() => {
              setCurrentIndex(index);
              setOptionsDrawerVisible(true);
            }}
          >
            <TuneIcon fontSize='small' />
          </IconButton>
        ),
        actions: (props) => (
          <div className='d-flex flex-row'>
            <IconButton
              onClick={() => {
                setId(props.element_id);
                setShowDeleteModal(true);
              }}
            >
              <DeleteIcon fontSize='small' color='error' />
            </IconButton>
            <IconButton
              onClick={() => {
                setCurrentIndex(index);
                setConfigDrawerOpen(true);
              }}
              disabled={
                ![
                  DocElementType.TextField,
                  DocElementType.SocialNumber,
                  DocElementType.Upload,
                  DocElementType.File,
                  DocElementType.MediaLink,
                ].includes(props.element_type)
              }
            >
              <SettingsIcon fontSize='small' />
            </IconButton>
          </div>
        ),
      };
    });
    setFormElements(tempFormElements);
  };

  const handleAddElement = () => {
    setAllElements((prevFormElements) => {
      const newElem = {
        label: '',
        display: true,
        help_id: '0',
        options: [],
        required: false,
        change_log: [],
        element_id: randomUUID(),
        user_value: null,
        element_name: prefix + '_field_' + Math.floor(10000 + Math.random() * 90000),
        element_type: 'T',
        verification: {
          type: 'static',
          verification_source: [],
        },
        match_pattern: '',
        sample_input: '',
        confirmed_value: [],
      };
      return [...prevFormElements, newElem];
    });
  };

  const handleDrag = (result) => {
    let tempElements = [...allElements];
    if (tempElements?.[result.source.index]?.parent_element) {
      const parentElementIndex = tempElements.findIndex(
        (element) => element.element_id === tempElements[result.source.index].parent_element
      );
      if (parentElementIndex >= result.destination.index) {
        return toast.warning('You can only drop child element withing parent element.');
      }
    }
    if (tempElements.length > 1) {
      tempElements = arrayMoveImmutable(
        tempElements,
        result.source.index,
        result.destination.index
      );
      setAllElements(tempElements);
    }
  };

  const rowsPerPageOptions = [10, 20, 30, 50, 100];

  const deleteHandler = async () => {
    setAllElements((prevElements) => {
      let tempElements = [...prevElements];
      tempElements.forEach((element) => {
        if (element.parent_element === id) {
          element.parent_element = null;
          element.parent_value = null;
        }
      });
      tempElements = tempElements.filter((element) => element.element_id !== id);
      return tempElements;
    });
    setShowDeleteModal(false);
  };

  return (
    <div className='mt-16 template-section'>
      <div className='d-flex justify-end mt-8 btn-edit'>
        {props?.details?.sections?.[props.currentSection]?.groups?.length ? (
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              handleAddElement();
            }}
            className='mb-8'
          >
            + Add
          </Button>
        ) : (
          ''
        )}
      </div>
      {formElements?.length ? (
        <StaticPaginationTable
          columns={columns}
          data={formElements?.length ? formElements : []}
          rowsPerPageOptions={rowsPerPageOptions}
          toSort={true}
          handleSort={handleDrag}
          page={page}
          setPage={setPage}
        />
      ) : (
        ''
      )}

      <ManageOptionsDrawer
        visible={isOptionDrawerVisible}
        setVisible={setOptionsDrawerVisible}
        options={allElements?.[currentIndex]?.options}
        currentIndex={currentIndex}
        setAllElements={setAllElements}
      />
      <FormPreview open={isPreviewModalOpen} setOpen={setPreviewModalOpen} data={props.details} />
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        item={'element'}
        submitFunction={deleteHandler}
      />
      <FieldConfigurationDrawer
        visible={isConfigDrawerOpen}
        setVisible={setConfigDrawerOpen}
        allElements={allElements}
        setAllElements={setAllElements}
        currentIndex={currentIndex}
      />
    </div>
  );
});
export default CreateQuestionsForm;
