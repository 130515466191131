import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CardContent, Grid, Typography, Chip, Select, MenuItem } from '@mui/material';
import Table from '../../../components/common/Table';
import { useSelector } from 'react-redux';
import { getOrders } from '../../../services/order/order';
import FilterPopper from '../../../components/common/FilterPopper';
import moment from 'moment';
import Loader from '../../../components/common/Loader';
import { AssignmentInd, Visibility } from '@mui/icons-material';
import ActionMenu from '../../../components/common/ActionMenu';
import {
  ADMIN_ROLE,
  CLIENT_ADMIN_ROLE,
  CLIENT_USER_ROLE,
  OFFICE_USER_ROLE,
  SUPER_ADMIN_ROLE,
} from '../../../utils/constants';
import { getOfficeList } from '../../../services/admin/users';
import AssignModal from '../../../components/order/AssignModal';
import { toast } from 'react-toastify';

const OrderList = () => {
  const navigate = useNavigate();
  const { order } = useSelector((state) => state?.authSlice);

  const isClientUser =
    order?.user_role_name === OFFICE_USER_ROLE || order?.user_role_name === CLIENT_USER_ROLE
      ? true
      : false;
  const clientLevelRole =
    order?.user_role_name === CLIENT_ADMIN_ROLE || order?.user_role_name === CLIENT_USER_ROLE
      ? true
      : false;
  const isAdmin =
    order?.user_role_name === ADMIN_ROLE || order?.user_role_name === SUPER_ADMIN_ROLE
      ? true
      : false;

  const [loading, setLoading] = useState(false);
  const [officeError, setOfficeError] = useState(false);
  const [officeListLoading, setOfficeListLoading] = useState(false);
  const [OrderList, setOrderList] = useState([]);
  const [total, setTotal] = useState(0);
  const [CurrPage, setCurrPage] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(100);
  const [anchor, setAnchor] = useState(null);
  const [anchor2, setAnchor2] = useState(null);
  const [anchor3, setAnchor3] = useState(null);
  const [anchor4, setAnchor4] = useState(null);
  const [anchor5, setAnchor5] = useState(null);
  const [anchor6, setAnchor6] = useState(null);
  const [anchor7, setAnchor7] = useState(null);
  const [anchor8, setAnchor8] = useState(null);
  const [anchor9, setAnchor9] = useState(null);
  const [filters, setFilters] = useState({});
  const [dateRange, setDateRange] = useState([{ startDate: '', endDate: '', key: 'selection' }]);
  const [isDateAdded, setIsDateAdded] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [offices, setOffices] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchOfficeList();
    if (order?.client_id && clientLevelRole) fetchClient();
  }, []);

  useEffect(() => {
    if (offices?.length) {
      if (offices?.length > 1) {
        let primaryOffice = offices.find((office) => office.is_primary);
        if (primaryOffice && !isAdmin) setSelectedOffice(primaryOffice?.office_id);
      } else {
        if (!isAdmin) {
          setSelectedOffice(offices?.[0]?.office_id);
        }
      }
    }
  }, [offices]);

  useEffect(() => {
    if (selectedOffice && clientLevelRole && offices?.length > 1)
      setFilters((prev) => {
        return { ...prev, client_office_id: String(selectedOffice) };
      });
  }, [selectedOffice]);

  useEffect(() => {
    fetchOrders();
  }, [CurrPage, perPageRecords]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (Object.keys(filters || {})?.length) {
        fetchOrders();
      }
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [filters]);

  useEffect(() => {
    setTimeout(() => {
      if (
        dateRange[0]?.startDate &&
        dateRange[0]?.startDate !== 'Invalid date' &&
        dateRange[0]?.endDate &&
        dateRange[0]?.endDate !== 'Invalid date'
      ) {
        setIsDateAdded(true);
        fetchOrders();
      } else {
        setIsDateAdded(false);
      }
    }, [1000]);
  }, [dateRange]);

  const fetchOfficeList = async () => {
    setOfficeListLoading(true);
    const res = await getOfficeList(order?.token, order?.client_id, order?.id);
    if (res?.response?.length) {
      const firstOfc = res?.response?.find((ofc) => ofc.status !== 'disabled');
      if (!firstOfc) {
        setOfficeError(true);
      } else {
        setOfficeError(false);
      }
    }
    setOfficeListLoading(false);
  };

  const fetchClient = async () => {
    setLoading(true);
    const res = await getOfficeList(order?.token, order?.client_id);
    if (res.success) {
      setOffices(res.response || []);
    }
    setLoading(false);
  };

  const fetchOrders = async (isClear = false) => {
    setLoading(true);
    let str = `page=${CurrPage + 1}&per_page=${perPageRecords}&is_listing=yes`;
    Object.keys(filters).forEach((key) => {
      if (filters?.[key]?.length) {
        str = str + `&${key === 'id' ? 'order_id' : key}=${filters?.[key]}`;
      }
    });
    if (dateRange[0]?.startDate && dateRange[0]?.endDate && !isClear) {
      str =
        str +
        `&order_date_from=${moment(
          dateRange?.[0]?.startDate,
          'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'
        ).format('YYYY-MM-DD')}&order_date_to=${moment(
          dateRange?.[0]?.endDate,
          'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'
        ).format('YYYY-MM-DD')}`;
    }
    const res = await getOrders(order?.token, str);
    if (res?.success) {
      setOrderList(res?.response?.data || []);
      setTotal(res?.response?.meta?.total);
    } else {
      setOrderList([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const tableData = useMemo(() => {
    if (OrderList?.length) {
      return OrderList?.map((orders) => {
        return {
          ...orders,
          created_by: (props) => (
            <div>
              {props?.created_by_user?.length ? (
                <div>{props?.created_by_user}</div>
              ) : (
                <div className='color-p'>Deleted User</div>
              )}
            </div>
          ),
          office_name: (props) => <div>{props?.client_office?.office_name ?? ''}</div>,
          order_status: (props) => (
            <div className={`${props?.order_status_name !== 'Completed' ? 'color-p' : 'color-s'}`}>
              {props?.order_status_name ?? ''}
            </div>
          ),
          // order_status: (props) => (
          //   <Chip
          //     label={props?.order_status_name}
          //     style={{
          //       height: '20px',
          //       padding: '2px',
          //       borderRadius: '5px',
          //     }}
          //     className={`${props?.order_status_name !== 'Completed' ? 'bg-p' : 'completed-bg'}`}
          //   />
          // ),
          actions: (props) => (
            <div>
              <ActionMenu
                actions={
                  isClientUser
                    ? [
                        {
                          label: 'Order Details',
                          logo: <Visibility className='action-menu-item' />,
                          onClick: () =>
                            navigate(`/${isClientUser ? 'orders' : 'admin/orders'}/${props.id}`, {
                              state: {},
                            }),
                        },
                      ]
                    : [
                        {
                          label: 'Order Details',
                          logo: <Visibility className='action-menu-item' />,
                          onClick: () =>
                            navigate(`/${isClientUser ? 'orders' : 'admin/orders'}/${props.id}`, {
                              state: {},
                            }),
                        },
                        {
                          label: 'Assign Order',
                          logo: <AssignmentInd className='action-menu-item' />,
                          onClick: () => {
                            setSelectedRecord(orders);
                            setOpen(true);
                          },
                        },
                      ]
                }
              />
            </div>
          ),
        };
      });
    }
    return [];
  }, [OrderList]);

  const columns = [
    { id: 'actions', label: 'Actions', type: 'jsx' },
    {
      id: 'escrow_num',
      label: 'Escrow No.',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center' style={{ width: '110px' }}>
            <div>Escrow No.</div>
            <FilterPopper
              anchor={anchor}
              setAnchor={setAnchor}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              type='text'
            />
          </div>
        );
      },
    },

    {
      id: 'order_status',
      label: 'Status',
      type: 'jsx',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center'>
            <div>Status</div>
            <FilterPopper
              anchor={anchor4}
              setAnchor={setAnchor4}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              type='select'
              options={[
                { label: 'Pending', value: 'Pending' },
                { label: 'In Progress', value: 'In Progress' },
                { label: 'Completed', value: 'Completed' },
              ]}
            />
          </div>
        );
      },
    },
    {
      id: 'company_name',
      label: 'Company Name',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center' style={{ width: '140px' }}>
            <div>Company Name</div>
            <FilterPopper
              anchor={anchor5}
              setAnchor={setAnchor5}
              field={'client_name'}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        );
      },
    },
    {
      id: 'office_name',
      label: 'Office Name',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center' style={{ width: '150px' }}>
            <div>Office Name</div>
            <FilterPopper
              anchor={anchor6}
              setAnchor={setAnchor6}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        );
      },
      type: 'jsx',
    },
    {
      id: 'created_by',
      label: 'Created By',
      type: 'jsx',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center' style={{ width: '150px' }}>
            <div>Created By</div>
            <FilterPopper
              anchor={anchor8}
              setAnchor={setAnchor8}
              field={'created_by_user'}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        );
      },
    },
    {
      id: 'assigned_to',
      label: 'Assigned To',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center' style={{ width: '150px' }}>
            <div>Assigned To</div>
            <FilterPopper
              anchor={anchor9}
              setAnchor={setAnchor9}
              field={'assignee_user'}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        );
      },
    },
    {
      id: 'order_type_name',
      label: 'Order Type',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center' style={{ width: '110px' }}>
            <div>Order Type</div>
            <FilterPopper
              anchor={anchor7}
              setAnchor={setAnchor7}
              field={'order_type'}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        );
      },
    },

    {
      id: 'address',
      label: 'Property Address',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center' style={{ width: '250px' }}>
            <div>Address</div>
            <FilterPopper
              anchor={anchor2}
              setAnchor={setAnchor2}
              field={props?.id}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              type='text'
            />
          </div>
        );
      },
    },
    {
      id: 'created_date',
      label: 'Order Date',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center' style={{ width: '120px' }}>
            <div>Order Date</div>
            <FilterPopper
              anchor={anchor3}
              setAnchor={setAnchor3}
              field={'created_at'}
              label={props?.label}
              filters={filters}
              setFilters={setFilters}
              dateRange={dateRange}
              setDateRange={setDateRange}
              fetchFormData={fetchOrders}
              type='date'
              isDateAdded={isDateAdded}
            />
          </div>
        );
      },
    },
    {
      id: 'order_completed_date',
      label: 'Completed On',
      render: (props) => {
        return (
          <div className='d-flex justify-start align-items-center' style={{ width: '100px' }}>
            <div>Completed On</div>
          </div>
        );
      },
    },
  ];

  const rowsPerPageOptions = [10];

  const filteredColumns = (() => {
    if (isAdmin) {
      return columns;
    } else {
      return columns.filter((column) => column.id !== 'company_name');
    }
  })();

  const onRowClick = (row) => {
    navigate(`/${isClientUser ? 'orders' : 'admin/orders'}/${row.id}`, {
      state: {},
    });
  };
  return (
    <Box className='dashboard-main'>
      <AssignModal
        open={open}
        setOpen={setOpen}
        record={selectedRecord}
        token={order?.token}
        fetchOrders={fetchOrders}
      />
      {(loading || officeListLoading) && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12} sm={12} xs={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid item md={12} className='d-flex justify-between align-center title-bar'>
                <Typography variant='h4' className='mb-0'>
                  Orders
                </Typography>
                <div className='flex-row'>
                  {offices?.length > 1 ? (
                    <div className='mr-8 table-select-filter'>
                      <label id='demo-simple-select-label'>Office : </label>
                      <Select
                        sx={{
                          // Reset common styles
                          marginLeft: '4px',
                          width: '200px',
                          // Add any additional styles you need
                        }}
                        defaultValue={selectedOffice}
                        displayEmpty={true}
                        value={selectedOffice}
                        onChange={(e) => {
                          setSelectedOffice(e.target.value);
                        }}
                      >
                        {offices?.map((office) => (
                          <MenuItem value={office.office_id} key={office.office_id}>
                            {office.office_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    ''
                  )}
                  {!isAdmin ? (
                    <Button
                      autoFocus
                      onClick={() => {
                        if (officeError)
                          return toast.error(
                            'There are no active offices available for your client. Please enable an office in the Client Offices section before proceeding with the order creation process.'
                          );
                        navigate(`/${isClientUser ? 'orders' : 'admin/orders'}/bulk-upload`);
                      }}
                      variant='contained'
                      color='primary'
                      style={{ padding: '4px 12px' }}
                      className='mr-8'
                    >
                      Upload Bulk Order
                    </Button>
                  ) : (
                    ''
                  )}
                  {!isAdmin ? (
                    <Button
                      autoFocus
                      onClick={() => {
                        if (officeError)
                          return toast.error(
                            'There are no active offices available for your client. Please enable an office in the Client Offices section before proceeding with the order creation process.'
                          );
                        navigate(`/${isClientUser ? 'orders' : 'admin/orders'}/add`);
                      }}
                      variant='contained'
                      color='primary'
                      style={{ padding: '4px 12px' }}
                    >
                      + Create Order
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </Grid>
            </CardContent>
            <Box>
              <CardContent>
                <Table
                  loader={loading}
                  columns={filteredColumns}
                  data={tableData}
                  rowsPerPageOptions={rowsPerPageOptions}
                  // linkDialog={true}
                  // openDialog={openDetailModal}
                  count={total}
                  current_page={CurrPage}
                  perPageRecord={perPageRecords}
                  setPerPageRecords={setPerPageRecords}
                  setCurrPage={setCurrPage}
                  isClickable={true}
                  onRowClick={onRowClick}
                />
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default OrderList;
