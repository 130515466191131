import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Alert, InputAdornment } from '@mui/material';
import logo from '../../../assets/images/Voxtur-verify.png';
import { useForm } from 'react-hook-form';
import { configuration, login } from '../../../store/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { userLogin, getConfigurations } from '../../../services/auth/auth';
import Loader from '../../common/Loader';
import { Email, Visibility, VisibilityOff } from '@mui/icons-material';
import { OFFICE_USER_ROLE, SUPER_ADMIN_EMAIl, CLIENT_USER_ROLE } from '../../../utils/constants';

export default function Login() {
  const url = window.location.origin;
  const { office } = useParams();
  const [loading, setLoading] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // const [showAlert, setShowAlert] = useState(false);
  const config = useSelector((state) => state.authSlice)?.configuration?.[url];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getConfigurationDetails();
  }, [office]);

  const getConfigurationDetails = async () => {
    setCompanyLoading(true);
    let body = { domain: url };
    if (office) {
      body.office_name = office;
    }
    const res = await getConfigurations(body);
    if (res.success) {
      let activeOffices = res?.response?.filter((ofc) => ofc.status !== 'disabled');
      if (activeOffices?.length > 1) {
        navigate('/offices');
        // const primaryOffice = res?.response?.find((ofc) => ofc.is_primary) || res?.response?.[0];
        // setCompanyLoading(false);
        // navigate(`/${primaryOffice?.office_name?.replace(' ', '')}/login`);
        // setShowAlert(true);
        // dispatch(
        //   configuration({
        //     url,
        //     data: {},
        //   })
        // );
      } else {
        dispatch(
          configuration({
            url,
            data: { ...(activeOffices?.[0] || {}) },
          })
        );
      }
    }
    setCompanyLoading(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    let bodyData = { ...data };
    if (config?.client_id && config?.client_office_id && data?.email !== SUPER_ADMIN_EMAIl) {
      bodyData.client_id = config.client_id;
      bodyData.client_office_id = config.client_office_id;
    }
    bodyData.domain = url;
    const resData = await userLogin(bodyData);
    if (resData?.success) {
      if (
        !resData?.response?.user?.is_password_changed ||
        resData?.response?.user?.is_password_changed === 'false'
      ) {
        navigate(
          `/reset-password?token=${encodeURIComponent(resData?.response?.token)}&&panel=admin`
        );
        return;
      }
      if (
        resData?.response?.user?.user_role_name === OFFICE_USER_ROLE ||
        resData?.response?.user?.user_role_name === CLIENT_USER_ROLE
      ) {
        dispatch(
          login({
            data: { ...resData?.response?.user, token: resData?.response?.token },
            code: 'order',
            showModal: false,
          })
        );
      } else {
        dispatch(
          login({
            data: { ...resData?.response?.user, token: resData?.response?.token },
            code: 'admin',
            showModal: true,
          })
        );
        dispatch(
          login({
            data: { ...resData?.response?.user, token: resData?.response?.token },
            code: 'order',
            showModal: true,
          })
        );
      }
      if (
        resData?.response?.user?.user_role_name === OFFICE_USER_ROLE ||
        resData?.response?.user?.user_role_name === CLIENT_USER_ROLE
      ) {
        navigate('/orders/order', { replace: true });
      } else {
        navigate('/admin/dashboard', { replace: true });
      }
    } else {
      if (resData?.response?.domain?.length) {
        toast.error(
          `You are not allowed to log in with this URL. We are redirecting you to your client's URL.`
        );
        setTimeout(() => {
          window.location.replace(`${resData?.response?.domain}/login`);
          setLoading(false);
        }, 5000);
        return; 
      }
      toast.error(resData?.error || resData?.message || 'Something went wrong!');
    }
    setLoading(false);
  };

  return (
    <div>
      <Box className='admin-box-wrapper'>
        {loading && <Loader />}
        {companyLoading ? (
          <Loader />
        ) : (
          <Box
            sx={{
              borderRadius: 2,
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            className='admin-box'
          >
            <Box variant='div' component='div' className='admin-logo'>
              <img
                src={config?.company_logo_url ? config?.company_logo_url : logo}
                alt='logo-main'
                loading='lazy'
                className='logo-size'
              />
            </Box>
            <Typography component='h1' variant='h4' className='login-head-txt'>
              Sign In
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              sx={{ mt: 1 }}
              className='reset-pwd'
              style={{ width: '100%' }}
            >
              <label className='login-label'>Email</label>
              <label className='required'>{'*'}</label>
              <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                name='email'
                className='mt-4'
                autoComplete='email'
                autoFocus
                {...register('email', { required: true })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Email fontSize='small' />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.email && (
                <span className='error mb-8' style={{ display: 'inherit' }}>
                  This Username is required
                </span>
              )}
              <label className='login-label'>Password</label>
              <label className='required'>{'*'}</label>
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                className='mt-4 mb-4'
                type={showPassword ? 'text' : 'password'}
                id='password'
                autoComplete='current-password'
                {...register('password', { required: true })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {showPassword ? (
                        <Visibility
                          fontSize={'small'}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      ) : (
                        <VisibilityOff
                          fontSize={'small'}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password && <span className='error'>This password is required</span>}
              <div
                className='mt-4 f-13-5 cursor-pointer color-p'
                onClick={() => {
                  navigate('/forgot-password', { state: { portal: 'admin' } });
                }}
                style={{ width: 'fit-content' }}
              >
                Forgot Password ?
              </div>
              <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                Sign In
              </Button>
              {/* {showAlert && (
                <Alert
                  severity='info'
                  className='f-12 fw-500'
                  style={{ textAlign: 'justify' }}
                  onClose={() => {
                    setShowAlert(false);
                  }}
                >
                  Your company operates from multiple offices. Please log in again. We've
                  pre-selected a primary office for you. Alternatively, you can enter the office
                  name without spaces in the URL if you work from a different office.
                </Alert>
              )} */}
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
}
