import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams, useNavigate } from 'react-router-dom';
import { setBreadcrumb } from '../store/slices/breadcrumbSlice';
import { checkAuthToken } from '../services/auth/auth';
import { parseStaticURLToDynamicValues } from '../utils/helpers';
import {
  CLIENT_ADMIN_ROLE,
  OFFICE_ADMIN_ROLE,
  OFFICE_USER_ROLE,
  CLIENT_USER_ROLE,
  SUPER_ADMIN_ROLE,
} from '../utils/constants';
import { logout } from '../store/slices/authSlice';
import { removeCurrentStep } from '../store/slices/questionSlice';

const PrivateRoute = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { code, participantNo } = params;
  const data = useSelector((state) => state?.authSlice);
  const order_part_participant_id = useSelector(
    (state) => state.questionSlice.questionDetails
  )?.find((que) => que.tx_id === code)?.order_part_participant_id;
  const isOfficeAdmin =
    props?.type === 'admin'
      ? data['admin']?.user_role_name === OFFICE_ADMIN_ROLE
        ? true
        : false
      : false;
  const isClientAdmin =
    props?.type === 'admin'
      ? data['admin']?.user_role_name === CLIENT_ADMIN_ROLE
        ? true
        : false
      : false;
  const isSuperAdmin =
    props?.type === 'admin'
      ? data['admin']?.user_role_name === SUPER_ADMIN_ROLE
        ? true
        : false
      : false;
  const isUser =
    props?.type === 'order'
      ? data['order']?.user_role_name === OFFICE_USER_ROLE ||
        data['order']?.user_role_name === CLIENT_USER_ROLE
        ? true
        : false
      : false;
  const [authenticated, setAuthenticated] = useState(true);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    if (props.onlySuperAdminAccess && !isSuperAdmin) {
      navigate('/admin/dashboard');
    }
    if (props.onlyAdminAccess && isClientAdmin) {
      navigate('/admin/dashboard');
    }
    if (props.onlyUserAccess && !isUser) {
      navigate('/admin/dashboard');
    }
    if (props.clientLevelAccess && isOfficeAdmin) {
      navigate('/admin/dashboard');
    }
  }, [pathname]);

  useEffect(() => {
    checkAuthenticated();
  }, [pathname]);

  // Dispatch the setBreadcrumb action with the provided title
  useEffect(() => {
    const updatedBreadcrumb = props?.breadcrumb?.map((item) => {
      if (Object.keys(params || {}).length) {
        return parseStaticURLToDynamicValues(item, params);
      }
      return item;
    });

    dispatch(setBreadcrumb({ data: updatedBreadcrumb, key: pathname }));
  }, [dispatch, props?.breadcrumb]);

  const checkAuthenticated = async () => {
    let user_id = '';
    let token = '';
    if (props.type === 'participant') {
      token = data[code]?.token;
      user_id = data[code]?.id;
    } else {
      token = data[props?.type]?.token;
      user_id = data[props?.type]?.id;
    }
    if (!token) {
      return setAuthenticated(false);
    }
    let payload = { user_id };
    if (pathname.split('/')[1] === 'questions') payload.participant_no = participantNo || 1;
    if (order_part_participant_id) payload.order_part_participant_id = order_part_participant_id;
    const res = await checkAuthToken(payload, token);
    if (res?.success) {
      setAuthenticated(true);
    } else {
      dispatch(
        logout({
          url: window.location.origin,
          code: code ? code : 'admin',
        })
      );
      if (code && participantNo && +participantNo !== 1) {
        dispatch(
          removeCurrentStep({
            tx_id: code,
          })
        );
      }
      setAuthenticated(false);
    }
  };

  return (
    <React.Fragment>
      {props.type === 'admin' ? (
        authenticated ? (
          props.component
        ) : (
          <Navigate to={`/login`} />
        )
      ) : props.type === 'order' ? (
        authenticated ? (
          props.component
        ) : (
          <Navigate to={`/login`} />
        )
      ) : authenticated ? (
        props.component
      ) : (
        <Navigate
          to={
            participantNo
              ? `/questions/invite/${code}/${participantNo}`
              : `/questions/invite/${code}`
          }
        />
      )}
    </React.Fragment>
  );
};

export default PrivateRoute;
