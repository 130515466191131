import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Avatar,
  Collapse,
  CssBaseline,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Chip,
  Alert,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import {
  FormatIndentIncrease,
  FormatIndentDecrease,
  SupervisorAccount,
  AdminPanelSettings,
  ManageAccounts,
  ExpandMore,
  Source,
  ExpandLess,
  ListAlt,
  QuestionAnswer,
  Dashboard,
  Person,
  Settings,
  SupervisorAccountOutlined,
  RecentActors,
  Article,
  Assessment,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../assets/images/Voxtur-verify.png';
import { logout } from '../../../store/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Breadcrumb from '../../common/Breadcrumb';
import { resetBreadcrumb } from '../../../store/slices/breadcrumbSlice';
import ListItemComponent from '../../common/ListItem';
import { adminLogout } from '../../../services/auth/auth';
import Loader from '../../common/Loader';
import ProfileDrawer from '../../common/ProfileDrawer';
import { toggleMenuCollapsed } from '../../../store/slices/breadcrumbSlice';
import {
  ADMIN_ROLE,
  CLIENT_ADMIN_ROLE,
  CLIENT_USER_ROLE,
  OFFICE_ADMIN_ROLE,
  SUPER_ADMIN_ROLE,
} from '../../../utils/constants';
import chroma from 'chroma-js';
import LogoComponent from './Logo';
import { getConfigurations } from '../../../services/auth/auth';

const MainLayout = ({ children }) => {
  const url = window.location.origin;
  const { admin } = useSelector((state) => state?.authSlice);
  const isMenuOpen = useSelector((state) => state?.breadcrumbSlice)?.['isMenuOpen'];
  const config = useSelector((state) => state.authSlice)?.configuration?.[url];
  const isSuperAdmin = admin?.user_role_name === SUPER_ADMIN_ROLE ? true : false;
  const isOfficeAdmin = admin?.user_role_name === OFFICE_ADMIN_ROLE ? true : false;
  const isClientAdmin = admin?.user_role_name === CLIENT_ADMIN_ROLE ? true : false;
  const clientLevelAccess =
    admin?.user_role_name === CLIENT_ADMIN_ROLE || admin?.user_role_name === CLIENT_USER_ROLE;
  const isAdmin = admin?.user_role_name === ADMIN_ROLE ? true : false;
  const [ddOpen, setDdOpen] = useState(true);
  const [ddOpen2, setDdOpen2] = useState(true);
  const [ddOpen3, setDdOpen3] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [disableOffices, setDisableOffices] = useState([]);

  useEffect(() => {
    getConfigurationDetails();
  }, []);

  const getConfigurationDetails = async () => {
    setLoading(true);
    let body = { domain: url };
    const res = await getConfigurations(body);
    if (res.success) {
      setDisableOffices(
        (res?.response?.filter((ofc) => ofc.status === 'disabled') || [])?.map(
          (ofc) => ofc.office_name
        )
      );
    } else {
      setDisableOffices([]);
    }
    setLoading(false);
  };

  const logoUrl = useMemo(() => {
    return config?.company_logo_url ? config.company_logo_url : Logo;
  }, [config?.company_logo_url]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      dispatch(toggleMenuCollapsed({ open: false }));
    }
  }, [window.innerWidth]);

  useEffect(() => {
    // This will preload the image
    const logoImage = new Image();
    logoImage.src = logoUrl;
  }, [logoUrl]);

  useEffect(() => {
    if (Object.keys(config?.branding_json || {})?.length) {
      document.documentElement.style.setProperty(
        '--primary-color',
        config?.branding_json?.primary_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--sidebar-color',
        config?.branding_json?.sidebar_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--sidebar-font-color',
        config?.branding_json?.sidebar_font_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--sidebar-font-darker-color',
        config?.branding_json?.sidebar_font_color
          ? chroma(config?.branding_json?.sidebar_font_color).darken(0.5).hex()
          : ''
      );
      document.documentElement.style.setProperty(
        '--sidebar-lighten-color',
        config?.branding_json?.sidebar_color
          ? chroma(config?.branding_json?.sidebar_color).brighten(0.5).hex()
          : ''
      );
      document.documentElement.style.setProperty(
        '--header-color',
        config?.branding_json?.header_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--header-font-color',
        config?.branding_json?.header_font_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--bg-color',
        config?.branding_json?.background_color ?? ''
      );
    }
  }, [config]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const drawerWidth = 240;
  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(5)})`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(6)})`,
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    })
  );

  const handleLogout = async () => {
    setLoading(true);
    try {
      const resData = await adminLogout(admin?.token);
      if (resData?.success) {
        dispatch(
          logout({
            code: 'admin',
          })
        );
        dispatch(resetBreadcrumb());
        navigate('/login');
      } else {
        toast.error(resData?.error || resData?.message || 'Something went wrong!');
      }
    } catch (error) {
      toast.error('Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = () => {
    setDdOpen((isMenuOpen !== undefined ? !isMenuOpen : false) ? true : ddOpen);
    dispatch(toggleMenuCollapsed({ open: isMenuOpen !== undefined ? !isMenuOpen : false }));
  };

  return (
    <>
      {loading && <Loader />}
      <CssBaseline />
      <div style={{ display: 'flex' }}>
        <Drawer
          variant='permanent'
          open={isMenuOpen}
          className='dashboard-sidebar'
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          style={{
            background: config?.branding_json?.sidebar_color
              ? config?.branding_json?.sidebar_color
              : '#fff',
          }}
        >
          <DrawerHeader>{isMenuOpen === true && <LogoComponent src={logoUrl} />}</DrawerHeader>
          <Divider />
          <div
            className={`${isMenuOpen ? 'list-item-wrapper' : ''}`}
            style={{ display: 'grid', gap: '3px' }}
          >
            <ListItemComponent
              label={'Dashboard'}
              route={'/admin/dashboard'}
              icon={<Dashboard fontSize='small' />}
              isMenuOpen={isMenuOpen}
            />

            {isSuperAdmin && (
              <ListItem
                onClick={() => setDdOpen((open) => !open)}
                style={{ cursor: 'pointer' }}
                className='sidebar-item'
                title={!isMenuOpen ? 'System Admin' : ''}
              >
                <ListItemIcon>
                  <AdminPanelSettings fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='System Admin' />
                {ddOpen ? (
                  <ExpandLess style={{ color: '#777777' }} fontSize='small' />
                ) : (
                  <ExpandMore style={{ color: '#777777' }} fontSize='small' />
                )}
              </ListItem>
            )}
            <Collapse in={ddOpen} timeout='auto' unmountOnExit style={{ marginLeft: '10px' }}>
              {isSuperAdmin && (
                <ListItemComponent
                  label={'Users'}
                  route={'/admin/list'}
                  icon={<SupervisorAccount fontSize='small' />}
                  isMenuOpen={isMenuOpen}
                />
              )}
            </Collapse>
            <ListItem
              onClick={() => setDdOpen2((open) => !open)}
              style={{ cursor: 'pointer' }}
              className='sidebar-item'
              title={!isMenuOpen ? 'Client' : ''}
            >
              <ListItemIcon>
                <SupervisorAccountOutlined fontSize='small' />
              </ListItemIcon>
              <ListItemText primary='Client' />
              {ddOpen2 ? (
                <ExpandLess style={{ color: '#777777' }} fontSize='small' />
              ) : (
                <ExpandMore style={{ color: '#777777' }} fontSize='small' />
              )}
            </ListItem>
            <Collapse in={ddOpen2} timeout='auto' unmountOnExit style={{ marginLeft: '10px' }}>
              {isSuperAdmin && (
                <ListItemComponent
                  label={'Offices'}
                  icon={<ManageAccounts fontSize='small' />}
                  route={'/admin/clients'}
                  isMenuOpen={isMenuOpen}
                />
              )}
              {!isSuperAdmin && admin?.client_id && (
                <ListItemComponent
                  label={'Offices'}
                  icon={<ManageAccounts fontSize='small' />}
                  route={`/admin/clients/${admin.user_id}/${admin.client_id}`}
                  isMenuOpen={isMenuOpen}
                />
              )}
              <ListItemComponent
                label={'Users'}
                route={'/admin/users'}
                icon={<SupervisorAccount fontSize='small' />}
                isMenuOpen={isMenuOpen}
              />{' '}
            </Collapse>

            <ListItem
              onClick={() => setDdOpen3((open) => !open)}
              style={{ cursor: 'pointer' }}
              className='sidebar-item'
              title={!isMenuOpen ? 'Configurations' : ''}
            >
              <ListItemIcon>
                <Settings fontSize='small' />
              </ListItemIcon>
              <ListItemText primary='Configurations' />
              {ddOpen3 ? (
                <ExpandLess style={{ color: '#777777' }} fontSize='small' />
              ) : (
                <ExpandMore style={{ color: '#777777' }} fontSize='small' />
              )}
            </ListItem>

            <Collapse in={ddOpen3} timeout='auto' unmountOnExit style={{ marginLeft: '10px' }}>
              {!isOfficeAdmin && (
                <ListItemComponent
                  label={'Preferences'}
                  route={'/admin/configurations'}
                  icon={<Settings fontSize='small' />}
                  isMenuOpen={isMenuOpen}
                />
              )}

              <ListItemComponent
                label={isSuperAdmin || isAdmin ? 'Master Form Configuration' : 'Form Configuration'}
                route={'/admin/templates'}
                icon={<ListAlt fontSize='small' />}
                isMenuOpen={isMenuOpen}
              />

              <ListItemComponent
                label={'Document Configuration'}
                route={'/admin/document-configurations'}
                icon={<Article fontSize='small' />}
                isMenuOpen={isMenuOpen}
              />
            </Collapse>
            {isSuperAdmin && (
              <ListItemComponent
                label={'Source Mapping'}
                route={'/admin/sources'}
                icon={<Source fontSize='small' />}
                isMenuOpen={isMenuOpen}
              />
            )}
            <ListItemComponent
              label={'Orders'}
              route={'/admin/orders'}
              icon={<QuestionAnswer fontSize='small' />}
              isMenuOpen={isMenuOpen}
            />

            <ListItemComponent
              label={'Audit Trails'}
              route={'/admin/audit-trails'}
              icon={<RecentActors fontSize='small' />}
              isMenuOpen={isMenuOpen}
            />
            {isSuperAdmin && (
              <ListItemComponent
                label={'Reports'}
                route={'/admin/reports'}
                icon={<Assessment fontSize='small' />}
                isMenuOpen={isMenuOpen}
              />
            )}

            {/* <ListItem button onClick={handleLogout} className='sidebar-item last-item'>
              <ListItemIcon>
                <ExitToAppIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText primary='Logout' />
            </ListItem> */}
          </div>
        </Drawer>
        <div style={{ flexGrow: 1, overflow: 'auto' }}>
          <Toolbar className='header-title'>
            <div className='d-flex align-items-center'>
              <IconButton onClick={handleToggle} className='mr-4 bread-crumb-btn'>
                {isMenuOpen === false ? <FormatIndentIncrease /> : <FormatIndentDecrease />}
              </IconButton>
              {window.innerWidth > 350 && <Breadcrumb />}
            </div>
            <div className='d-flex flex-row'>
              <div className='profile-chip'>
                {isClientAdmin && (
                  <>
                    <Chip
                      label={`Client : ${admin?.company_name}`}
                      variant='outlined'
                      className='mr-16'
                      color='primary'
                    />
                  </>
                )}
                {isOfficeAdmin && (
                  <>
                    <Chip
                      label={`Client : ${admin?.company_name}`}
                      variant='outlined'
                      className='mr-16'
                      color='primary'
                    />
                    <Chip
                      label={`Office : ${
                        admin?.offices?.length > 1
                          ? 'All Offices'
                          : admin?.offices?.[0]?.office_name
                      }`}
                      variant='outlined'
                      className='mr-16'
                      color='primary'
                    />
                  </>
                )}
              </div>
              <div className='profile-chip user-name'>
                <Chip
                  onClick={handleDrawerOpen}
                  avatar={
                    <Avatar
                      sx={{ width: '34px', height: '34px', fontSize: '16px' }}
                      className='mr-4 cursor-pointer bg-p'
                    >
                      <Person style={{ color: 'white' }} />
                    </Avatar>
                  }
                  label={admin?.first_name + ' ' + admin?.last_name}
                  variant='outlined'
                  className='cursor-pointer'
                  color='primary'
                />
              </div>
            </div>
          </Toolbar>
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            {clientLevelAccess && disableOffices?.length ? (
              <Alert severity='warning' className='f-13 fw-500'>
                {disableOffices?.join(', ')} office(s) is permanently closed. You can no longer
                create new orders or users for this office(s).
              </Alert>
            ) : (
              ''
            )}
          </div>

          {children}
        </div>
      </div>
      <ProfileDrawer open={drawerOpen} onClose={handleDrawerClose} type='admin' />
    </>
  );
};

export default MainLayout;
