import React, { useEffect } from 'react';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import logo from '../../../assets/images/Voxtur-verify.png';
import { useTranslation } from 'react-i18next';

const StepsHeader = ({ activeStep, steps, stepChange, logoUrl }) => {
  const { t } = useTranslation();
  const findValueToMinus = () => {
    if (window.innerWidth > 800) {
      return 400;
    } else if (window.innerWidth > 600) {
      return 200;
    } else if (window.innerWidth > 400) {
      return -150;
    } else if (window.innerWidth > 300) {
      return -250;
    }
  };
  const findValueToMinusForFirstStep = () => {
    if (window.innerWidth > 800) {
      return 80;
    } else if (window.innerWidth > 600) {
      return 80;
    } else if (window.innerWidth > 400) {
      return -150;
    } else if (window.innerWidth > 300) {
      return -250;
    }
  };
  useEffect(() => {
    let steps_container = document.querySelector('.steps');
    if (steps_container) {
      let active_step_element = document.querySelector(`.active-step`);
      if (active_step_element) {
        steps_container.scrollLeft =
          active_step_element.offsetLeft -
          (activeStep > 1 ? findValueToMinus() : findValueToMinusForFirstStep());
        if (activeStep === 0) {
          steps_container.scrollLeft = 0;
        }
      }
    }
  }, [activeStep]);

  const elemHeight = document.getElementById('card-main-form');
  const containerHight =
    window.innerWidth < 800
      ? 'unset'
      : elemHeight?.clientHeight
      ? elemHeight?.clientHeight + 'px'
      : '80vh';

  return (
    <div
      style={{
        minHeight: containerHight,
      }}
      className='main-header-container'
    >
      <div className='head-logo'>
        <img src={logoUrl ? logoUrl : logo} alt='logo-main' loading='lazy' className='logo-size' />
      </div>
      <hr className='logo-hr' />
      <Box
        sx={{
          padding: '12px',
          height: '100%',
        }}
        className='steps-scroll-wrap side-steps'
      >
        <Stepper activeStep={activeStep} className='steps' orientation='vertical'>
          {steps?.map((label, index) => (
            <Step
              key={label + '-' + index}
              className={index === activeStep ? 'active-bg-item' : 'cursor-pointer'}
              onClick={() => {
                stepChange(index);
              }}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
          <Step key={'Signature'} className={steps?.length === activeStep ? 'active-bg-item' : ''}>
            {/* <StepLabel>{'Signature & Documents'}</StepLabel> */}
            <StepLabel>{t('lastSection')}</StepLabel>
          </Step>
        </Stepper>
      </Box>
    </div>
  );
};

export default StepsHeader;
