import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { ErrorOutline, TaskAlt } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { savePayload } from '../../services/questions/moby';
import Loader from '../../components/common/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { autoLoginParticipant } from '../../services/questions/invite';
import { login } from '../../store/slices/authSlice';
import { getCompanyData, getOrderData } from '../../services/questions/home';
import { addCompanyDetails, addOrderDetails } from '../../store/slices/questionSlice';

const MobySuccess = () => {
  const { code } = useParams();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.authSlice)?.[code]?.token;
  const { search } = useLocation();
  const navigate = useNavigate();
  const [resData, setResData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setIsMobile(true);
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsMobile(true);
    }
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);


  useEffect(() => {
    const res = JSON.parse(new URLSearchParams(search).get('Response'));
    if (res) {
      saveMobyPayload();
    }
  }, []);

  const domainName = window.location.origin;

  const saveMobyPayload = async () => {
    setLoading(true);
    const res = await savePayload({
      participant_uuid: code,
      moby_response: JSON.parse(new URLSearchParams(search).get('Response')),
    });
    if (res.success) {
      setResData(JSON.parse(new URLSearchParams(search).get('Response')));
      if (isMobile) {
        const currentPath = window.location.pathname;
        navigate(currentPath, { replace: true });
        setLoading(false);
      } else {
        setTimeout(() => {
          window.parent.postMessage(`closeModal-${code}`, '*');
          setLoading(false);
        }, 2000);
      }
    } else {
      window.parent.postMessage(`closeModal-${code}`, '*');
      setLoading(false);
    }
  };

  // const title = resData?.Approved ? 'SUCCESS' : 'NOT APPROVED';

  const message = resData?.Approved
    ? `Your government-issued ID has been successfully uploaded. You can continue filling out the form.`
    : `Your government-issued ID has been uploaded. You can continue filling out the form.`;

  // const icon = resData?.Approved ? (
  //   <TaskAlt style={{ fontSize: '100px', color: 'green' }} />
  // ) : (
  //   <ErrorOutline style={{ fontSize: '100px', color: '#dc3838' }} />
  // );

  // const bgColor = resData?.Approved ? 'green' : '#dc3838';

  const handleContinue = async () => {
    if (!token && code) {
      setLoading(true);
      const res = await autoLoginParticipant(code);

      if (res?.success) {
        dispatch(
          login({
            data: { ...res?.response?.user, token: res?.response?.token },
            code: code,
          })
        );
        const orderData = await getOrderData(code, res?.response?.token);
        if (orderData?.success) {
          dispatch(addOrderDetails({ tx_id: code, order: orderData?.response }));
        }
        const data = await getCompanyData(code);
        if (data.success) {
          dispatch(addCompanyDetails({ tx_id: code.toString(), company: data?.response }));
        }
      } else {
        toast.error(res?.error || res?.message || 'Something went wrong!');
      }
      setLoading(false);
    }
    navigate(`/questions/home/${code}`, { replace: true });
  };

  return (
    <Box className='admin-box-wrapper'>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            borderRadius: 2,
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          className='admin-box moby-payload'
        >
          {/* <Box variant='div' component='div' className='admin-logo'>
            <Box variant='div' component='div'>
              {icon}
            </Box>
          </Box>
          <div className='success'>{title}</div>
          {resData?.ErrorMessage?.length ? (
            <div className='error f-13'>{resData?.ErrorMessage}</div>
          ) : (
            ''
          )} */}

          {isMobile ? (
            <div className='f-13 fw-450 mt-8 color-g' style={{ letterSpacing: '0.1px' }}>
              {message}{' '}
              <span
                className='color-p cursor-pointer'
                onClick={() => {
                  copy(`${domainName}/questions/home/${code}`);
                  toast.success('Copied to clipboard!');
                }}
              >
                Click here
              </span>{' '}
              to copy the participant form link or click the continue button below.
            </div>
          ) : (
            <></>
            // <div className='f-13 fw-450 mt-8 color-g' style={{ letterSpacing: '0.1px' }}>
            //   {resData?.Approved
            //     ? `Your ${
            //         resData?.IdentificationTypeId && ID_MAPPING?.[resData?.IdentificationTypeId]
            //           ? ID_MAPPING?.[resData?.IdentificationTypeId]
            //           : 'Document'
            //       } has been verified successfully. You can close this popup and continue filling out the form.`
            //     : `There was an error verifying your document. Please close this popup and try again.`}
            // </div>
          )}
          {isMobile && (
            <div className='f-13 fw-450 mt-8 color-p' style={{ letterSpacing: '0.1px' }}>
              Note : Clicking Continue here will lead you to fill out the form from your mobile
              phone. If you would like to finish filling out the form from your desktop, please
              close out of this window and proceed to your desktop version.
            </div>
          )}

          {isMobile ? (
            <Button
              fullWidth
              onClick={() => {
                handleContinue();
              }}
              variant='contained'
              // style={{ backgroundColor: bgColor }}
              sx={{ mt: 3, mb: 2 }}
            >
              Continue
            </Button>
          ) : (
            ''
          )}
        </Box>
      )}
    </Box>
  );
};
export default MobySuccess;
