import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchFormDetails = createAsyncThunk(
  'questionSlice/fetchFormValues',
  async ({ code, id }, thunkAPI) => {
    const state = await thunkAPI.getState();
    const formValues = state.questionSlice.formValues.find((value) => value.tx_id === code);
    const errors = state.questionSlice.errors.find((value) => value.tx_id === code);
    const touchedFields = state.questionSlice.touchedFields.find((value) => value.tx_id === code);
    return {
      value: formValues?.values?.[id] || null,
      error: errors?.values?.[id] || null,
      isTouched: touchedFields?.fields?.[id] || false,
    };
  }
);

const initialState = {
  questionDetails: [],
  orderDetails: [],
  companyDetails: [],
  formValues: [],
  touchedFields: [],
  documents: {},
  stepData: {},
  languages: {},
  errors: [],
  creditCardInfo: [],
  paddingDetails: [],
  additionalParticipants: [],
  convertedTexts: [],
  dlDetails: {},
  qrDetails: {},
};

const questionSlice = createSlice({
  name: 'questionSlice',
  initialState,
  reducers: {
    addDlDetails: (state, action) => {
      if (state?.dlDetails) {
        state.dlDetails[action.payload.tx_id] = action.payload.data;
      } else {
        state.dlDetails = { [action.payload.tx_id]: action.payload.data };
      }
    },
    removeDlDetails: (state, action) => {
      if (state?.dlDetails?.[action.payload.tx_id]) {
        delete state.dlDetails[action.payload.tx_id];
      }
    },
    addQRDetails: (state, action) => {
      if (state?.qrDetails) {
        state.qrDetails[action.payload.tx_id] = action.payload.data;
      } else {
        state.qrDetails = { [action.payload.tx_id]: action.payload.data };
      }
    },
    removeQRDetails: (state, action) => {
      if (state?.qrDetails?.[action.payload.tx_id]) {
        delete state.qrDetails[action.payload.tx_id];
      }
    },
    addQuestionDetails: (state, action) => {
      let data = {
        tx_id: action.payload.tx_id,
        questions: action.payload.questions,
        sources: action.payload.sources,
        is_pdf_generated: action.payload.is_pdf_generated,
        template_id: action.payload.template_id,
        order_part_participant_id: action.payload.order_part_participant_id,
        documents: action.payload.documents,
      };
      const isAvailable = state?.questionDetails?.find(
        (question) => question.tx_id === action.payload.tx_id
      );
      if (isAvailable) {
        state.questionDetails = state.questionDetails.filter(
          (question) => question.tx_id !== action.payload.tx_id
        );
      }
      state?.questionDetails?.push(data);
    },
    addOrderDetails: (state, action) => {
      let data = {
        tx_id: action.payload.tx_id,
        order: action.payload.order,
      };
      const isAvailable = state?.orderDetails?.find(
        (order) => order.tx_id === action.payload.tx_id
      );
      if (isAvailable) {
        state.orderDetails = state.orderDetails.filter(
          (order) => order.tx_id !== action.payload.tx_id
        );
      }
      state?.orderDetails?.push(data);
    },
    addCompanyDetails: (state, action) => {
      let data = {
        tx_id: action.payload.tx_id,
        company: action.payload.company,
      };
      const isAvailable = state?.companyDetails?.find(
        (company) => company.tx_id === action.payload.tx_id
      );
      if (isAvailable) {
        state.companyDetails = state.companyDetails.filter(
          (company) => company.tx_id !== action.payload.tx_id
        );
      }

      state?.companyDetails.push(data);
    },
    addFormValue: (state, action) => {
      const index = state?.formValues?.findIndex((form) => form.tx_id === action.payload.tx_id);
      if (index !== -1) {
        state.formValues[index].values[action.payload.id] = action.payload.value;
      } else {
        state.formValues.push({
          tx_id: action.payload.tx_id,
          values: { [action.payload.id]: action.payload.value },
        });
      }
    },
    clearFormValue: (state, action) => {
      const index = state?.formValues?.findIndex((form) => form.tx_id === action.payload.tx_id);
      action.payload.ids.forEach((e) => delete state.formValues?.[index]?.values[e]);
    },
    clearFormValues: (state, action) => {
      state.formValues = state.formValues.filter((form) => form.tx_id !== action.payload.tx_id);
    },
    addTouchedFields: (state, action) => {
      const index = state?.touchedFields?.findIndex((form) => form.tx_id === action.payload.tx_id);
      if (index !== -1) {
        state.touchedFields[index].fields[action.payload.id] = true;
      } else {
        state.touchedFields.push({
          tx_id: action.payload.tx_id,
          fields: { [action.payload.id]: true },
        });
      }
    },
    clearTouchedField: (state, action) => {
      const index = state?.touchedFields?.findIndex((form) => form.tx_id === action.payload.tx_id);
      action.payload.ids.forEach((e) => delete state.touchedFields?.[index]?.fields[e]);
    },
    clearTouchedFields: (state, action) => {
      state.touchedFields = state.touchedFields.filter(
        (form) => form.tx_id !== action.payload.tx_id
      );
    },
    addErrorDetails: (state, action) => {
      const index = state?.errors?.findIndex((error) => error.tx_id === action.payload.tx_id);
      if (index !== -1) {
        state.errors[index].values[action.payload.id] = {
          error: action.payload.error,
          error_message: action.payload.error_message,
          error_type: action.payload.error_type,
        };
      } else {
        state.errors.push({
          tx_id: action.payload.tx_id,
          values: {
            [action.payload.id]: {
              error: action.payload.error,
              error_message: action.payload.error_message,
              error_type: action.payload.error_type,
            },
          },
        });
      }
    },
    clearErrorDetail: (state, action) => {
      const index = state?.errors?.findIndex((error) => error.tx_id === action.payload.tx_id);
      delete state?.errors?.[index]?.values?.[action.payload.id];
    },
    clearErrorDetails: (state, action) => {
      state.errors = state.errors.filter((error) => error.tx_id !== action.payload.tx_id);
    },
    setDocuments: (state, action) => {
      state.documents[action.payload.tx_id] = action.payload.documents;
    },
    clearQuestionStore: (state, action) => {
      state.formValues = state.formValues.filter((form) => form.tx_id !== action.payload.tx_id);
      state.errors = state.errors.filter((error) => error.tx_id !== action.payload.tx_id);
      state.companyDetails = state.companyDetails.filter(
        (company) => company.tx_id !== action.payload.tx_id
      );
      state.orderDetails = state.orderDetails.filter(
        (order) => order.tx_id !== action.payload.tx_id
      );
      state.questionDetails = state.questionDetails.filter(
        (question) => question.tx_id !== action.payload.tx_id
      );
      delete state.documents[action.payload.tx_id];
    },
    addCurrentStep: (state, action) => {
      state.stepData[action.payload.tx_id] = action.payload.step;
    },
    removeCurrentStep: (state, action) => {
      state.stepData[action.payload.tx_id] = 0;
    },
    addCreditCardInfo: (state, action) => {
      let data = {
        show: action.payload.show,
        info: action.payload.info,
        tx_id: action.payload.tx_id,
      };
      const isAvailable = state?.creditCardInfo?.find(
        (question) => question.tx_id === action.payload.tx_id
      );
      if (isAvailable) {
        state.creditCardInfo = state.creditCardInfo.filter(
          (question) => question.tx_id !== action.payload.tx_id
        );
      }
      if (state?.creditCardInfo?.length) {
        state?.creditCardInfo?.push(data);
      } else {
        state.creditCardInfo = [data];
      }
    },
    addPaddingDetails: (state, action) => {
      let data = {
        isCollapsed: action.payload.isCollapsed,
        padding: action.payload.padding,
        tx_id: action.payload.tx_id,
      };
      const isAvailable = state?.paddingDetails?.find(
        (question) => question.tx_id === action.payload.tx_id
      );
      if (isAvailable) {
        state.paddingDetails = state.paddingDetails.filter(
          (question) => question.tx_id !== action.payload.tx_id
        );
      }
      if (state?.paddingDetails?.length) {
        state?.paddingDetails?.push(data);
      } else {
        state.paddingDetails = [data];
      }
    },
    additionalParticipantToggle: (state, action) => {
      let data = {
        value: action.payload.value,
        tx_id: action.payload.tx_id,
      };
      const isAvailable = state?.additionalParticipants?.find(
        (question) => question.tx_id === action.payload.tx_id
      );
      if (isAvailable) {
        state.additionalParticipants = state.additionalParticipants.filter(
          (question) => question.tx_id !== action.payload.tx_id
        );
      }
      if (state?.additionalParticipants?.length) {
        state?.additionalParticipants?.push(data);
      } else {
        state.additionalParticipants = [data];
      }
    },
    addLanguage: (state, action) => {
      if (!Object.keys(state?.languages || {})?.length) {
        state.languages = { [action.payload.tx_id]: action.payload.language };
      } else {
        state.languages[action.payload.tx_id] = action.payload.language;
      }
    },
    addConvertedTexts: (state, action) => {
      let data = {
        tx_id: action.payload.tx_id,
        [action.payload.language]: action.payload.translations,
      };
      const isAvailable = state?.convertedTexts?.find(
        (question) => question.tx_id === action.payload.tx_id
      );
      if (isAvailable) {
        state.convertedTexts = state.convertedTexts.filter(
          (question) => question.tx_id !== action.payload.tx_id
        );
      }
      if (state?.convertedTexts?.length) {
        state?.convertedTexts?.push(data);
      } else {
        state.convertedTexts = [data];
      }
    },
    removeConvertedTexts: (state, action) => {
      state.convertedTexts = state.convertedTexts.filter(
        (question) => question.tx_id !== action.payload.tx_id
      );
    },
    clearQuestionSlice: (state, action) => {
      let tempState = { ...initialState, companyDetails: state?.companyDetails };
      return tempState;
    },
  },
});

export const {
  addQRDetails,
  clearQuestionSlice,
  removeQRDetails,
  addDlDetails,
  removeDlDetails,
  addQuestionDetails,
  addOrderDetails,
  addCompanyDetails,
  addFormValue,
  clearFormValue,
  clearFormValues,
  addTouchedFields,
  clearTouchedField,
  clearTouchedFields,
  addErrorDetails,
  clearErrorDetail,
  clearErrorDetails,
  setDocuments,
  addCurrentStep,
  clearQuestionStore,
  removeCurrentStep,
  addCreditCardInfo,
  additionalParticipantToggle,
  addPaddingDetails,
  addLanguage,
  addConvertedTexts,
  removeConvertedTexts,
} = questionSlice.actions;

export default questionSlice.reducer;
