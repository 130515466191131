import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ label, onClick, variant, disabled, icon, buttonClass }) => {
  if (variant === 'primary') {
    buttonClass += ' button-primary';
  } else if (variant === 'secondary') {
    buttonClass += ' button-secondary';
  }

  return (
    <button className={buttonClass} onClick={onClick} disabled={disabled}>
      {icon && <span className='button-icon'>{icon}</span>}
      {label}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  icon: PropTypes.element, // You can use React elements for icons
};

Button.defaultProps = {
  variant: 'primary',
  disabled: false,
};

export default Button;
