import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Tooltip,
} from '@mui/material';
import TableLoader from './TableLoader';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const CommonTable = ({
  columns,
  data,
  loader,
  toSort,
  handleSort,
  linkDialog,
  isClickable = false,
  count,
  current_page = 0,
  perPageRecord = 10,
  setPerPageRecords = () => {},
  setCurrPage,
  hidePagination = false,
  onRowClick = () => {},
}) => {
  const [page, setPage] = useState(current_page);

  const handleChangePage = (event, newPage) => {
    setCurrPage(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setCurrPage(0);
    setPerPageRecords(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <div className='table-div'>
        <Table style={{ width: '100%', overflow: 'scroll' }}>
          <TableHead>
            <TableRow>
              {toSort && <TableCell key={1111}>{'Sort'}</TableCell>}

              {columns.map((column) => {
                if (column?.render) {
                  return (
                    <TableCell key={column.id} style={{ padding: '12px' }}>
                      {column['render'](column)}
                    </TableCell>
                  );
                } else {
                  return <TableCell key={column.id}>{column.label}</TableCell>;
                }
              })}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleSort}>
            <Droppable droppableId='test'>
              {(provided, snapshot) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {!data.length && !loader ? (
                    <TableRow>
                      <TableCell colSpan={columns?.length} className='no-record'>
                        No records found.
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.map((row, index) => (
                      <Draggable draggableId={row.element_id} index={index}>
                        {(provided) => (
                          <TableRow
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={isClickable ? 'cursor-pointer open-link' : ''}
                            onDoubleClick={() => {
                              onRowClick(row);
                            }}
                          >
                            {toSort && (
                              <TableCell key={1111}>
                                <div
                                  {...provided.dragHandleProps}
                                  className='drag-icon'
                                  style={{ cursor: 'grab' }}
                                  // key={index}
                                >
                                  <DragIndicatorIcon fontSize='small' />
                                </div>
                              </TableCell>
                            )}
                            {columns.map((column) => {
                              if (column.type === 'jsx') {
                                // If the column is jsx type, execute the  function and render its JSX
                                return (
                                  <TableCell
                                    width={column?.width ? column?.width : 'unset'}
                                    key={column.id}
                                  >
                                    {row[column.id](row)}
                                  </TableCell>
                                );
                              }
                              return (
                                <TableCell
                                  width={column?.width ? column?.width : 'unset'}
                                  key={column.id}
                                >
                                  {row[column.id] ? row[column.id] : '-'}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        )}
                      </Draggable>
                    ))
                  )}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </div>
      {!hidePagination && (
        <div>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 200]}
            component='div'
            count={count || data?.length}
            rowsPerPage={perPageRecord}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className='pagination-container'
          />
        </div>
      )}
    </div>
  );
};

export default CommonTable;
