import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, CardContent, Grid, Button, Select, MenuItem, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import Table from '../../../components/common/Table';
import { getList } from '../../../services/admin/reports';
import Loader from '../../../components/common/Loader';
import { SaveAlt, ViewTimeline } from '@mui/icons-material';
import ActionMenu from '../../../components/common/ActionMenu';
import TimelineDrawer from '../../../components/admin/reports/TimelineDrawer';
import { CSVLink } from 'react-csv';
import { getClients } from '../../../services/admin/users';
import DateRangeFilter from './DateRangeFilter';
import moment from 'moment';
const dateFields = [
  'order_date_from',
  'order_date_to',
  'form_completed_date_to',
  'form_completed_date_from',
  'verification_completed_date_from',
  'verification_completed_date_to',
  'form_completed_date_from',
];
// const test = {
//   order_id: 228,
//   company_name: 'Office Questions',
//   address: ' 18721 Paseo Picasso, Irvine, Ca, 92603',
//   created_date: '07/05/2024',
//   order_completed_date: '',
//   question_form: [
//     {
//       user_participant_type: 'Seller',
//       data_verified_at: '',
//       participants: [
//         {
//           participant_name: 'John Doe',
//           participant_number: 1,
//           form_completed_date: '',
//         },
//       ],
//     },
//     {
//       user_participant_type: 'Buyer',
//       data_verified_at: '',
//       participants: [
//         {
//           participant_name: 'Buyer Name',
//           participant_number: 1,
//           form_completed_date: '',
//         },
//       ],
//     },
//   ],
// };
const Reports = () => {
  const csvExport = useRef();
  const { admin } = useSelector((state) => state?.authSlice);
  const [loading, setLoading] = useState(false);
  const [perPageRecords, setPerPageRecords] = useState(100);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [anchor1, setAnchor1] = useState(null);
  const [anchor2, setAnchor2] = useState(null);
  const [anchor3, setAnchor3] = useState(null);
  const [visible, setVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    fetchClientList();
  }, []);

  const fetchClientList = async () => {
    const res = await getClients(admin?.token);
    if (res?.success) {
      setClientList(
        res?.response.map((client) => {
          return { label: client.full_name, value: client.id };
        }) || []
      );
    }
  };
  const tableData = useMemo(() => {
    if (list?.length) {
      return list?.map((record) => {
        return {
          ...record,
          actions: (props) => (
            <div>
              <ActionMenu
                actions={[
                  {
                    label: `View Participant's Progress`,
                    logo: <ViewTimeline className='action-menu-item' />,
                    onClick: () => {
                      setVisible(true);
                      setCurrentRecord(record);
                    },
                  },
                ]}
              />
            </div>
          ),
        };
      });
    }
    return [];
  }, [list]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (Object.keys(filters || {})?.length) {
        fetchList();
      }
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [filters]);

  useEffect(() => {
    fetchList();
  }, [currPage, perPageRecords]);
  const fetchList = async (isClear = false) => {
    setLoading(true);
    let str = `page=${currPage + 1}&per_page=${perPageRecords}&is_listing=yes`;
    Object.keys(filters).forEach((key) => {
      if (filters?.[key]) {
        if (dateFields?.includes(key)) {
          str = str + `&${key}=${moment(filters?.[key]).format('YYYY-MM-DD')}`;
        } else {
          str = str + `&${key}=${encodeURIComponent(filters?.[key])}`;
        }
      }
    });
    const res = await getList(admin?.token, str);
    if (res?.success) {
      //   setList(res?.response || []);
      setList(res?.response?.data || []);
      setTotal(res?.response?.meta?.total);
    } else {
      setList([]);
      setTotal(0);
    }
    setLoading(false);
  };
  const getCSVData = async () => {
    setLoading(true);
    let str = `page=${currPage + 1}&per_page=${perPageRecords}&is_listing=no`;
    Object.keys(filters).forEach((key) => {
      if (filters?.[key]) {
        if (dateFields?.includes(key)) {
          str = str + `&${key}=${moment(filters?.[key]).format('YYYY-MM-DD')}`;
        } else {
          str = str + `&${key}=${encodeURIComponent(filters?.[key])}`;
        }
      }
    });
    const res = await getList(admin?.token, str);
    if (res?.success) {
      let tempData = res?.response?.data;
      let generatedData = [];
      tempData.forEach((record) => {
        generatedData.push({
          'Escrow No.': `\u200B${record?.escrow_num}` ?? '',
          Company: record?.company_name ?? '',
          Address: record?.address ?? '',
          'Order Creation Date': record?.created_date ?? '',
          'Order Completion Date': record?.order_completed_date ?? '',
          'Processing Time (Days)': record?.order_completed_days ?? '',
        });
      });
      setCsvData(generatedData);
      setTimeout(() => {
        csvExport.current.link.click();
      }, 1000);
      console.log(res?.response?.data);
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };
  const columns = [
    { id: 'actions', label: 'Actions', type: 'jsx' },

    {
      id: 'escrow_num',
      label: 'Escrow No.',
      // render: (props) => {
      //   return (
      //     <div className='d-flex justify-start align-items-center'>
      //       <div>Escrow No.</div>
      //       <FilterPopper
      //         anchor={anchor1}
      //         setAnchor={setAnchor1}
      //         field={props.id}
      //         label={props?.label}
      //         filters={filters}
      //         setFilters={setFilters}
      //         type='text'
      //       />
      //     </div>
      //   );
      // },
    },
    {
      id: 'company_name',
      label: 'Company',
      // render: (props) => {
      //   return (
      //     <div className='d-flex justify-start align-items-center'>
      //       <div>Company</div>
      //       <FilterPopper
      //         anchor={anchor2}
      //         setAnchor={setAnchor2}
      //         field={'client_name'}
      //         label={props?.label}
      //         filters={filters}
      //         setFilters={setFilters}
      //         type='text'
      //       />
      //     </div>
      //   );
      // },
    },
    {
      id: 'address',
      label: 'Address',
      width: '400px',
    },
    {
      id: 'created_date',
      label: 'Order Created Date',
    },
    {
      id: 'order_completed_days',
      label: 'Processing Time (Days)',
    },
  ];

  const onRowClick = (row) => {
    setCurrentRecord(row);
    setVisible(true);
  };

  const setDate = (dateName, value) => {
    setFilters((prev) => {
      return { ...prev, [dateName]: value };
    });
  };

  const getCSVName = () => {
    if (filters?.client_name) {
      return `order_report_${filters?.client_name?.toLowerCase()?.replace(/ /g, '_')}.csv`;
    }
    return `order_report_all.csv`;
  };

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <TimelineDrawer visible={visible} setVisible={setVisible} record={currentRecord} />
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent style={{ padding: '0px 16px' }}>
              <Grid item md={12} className='d-flex justify-between align-center'>
                <Grid container className='report-filter'>
                  <Grid container></Grid>
                  <Grid container>
                    {' '}
                    <Grid item sm={12} md={11}>
                      <Grid container spacing={1} className='reporting-filters-main'>
                        <Grid item className='filter-select'>
                          <div className='f-13 fw-550 mb-4'>Company Name</div>
                          <Select
                            sx={{
                              width: '13vw',
                              // borderRadius: '0px',
                            }}
                            defaultValue={''}
                            displayEmpty={true}
                            value={filters?.company_id}
                            onChange={(e) => {
                              setFilters((old) => {
                                return { ...old, client_name: e.target.value };
                              });
                            }}
                            placeholder='Select Company'
                          >
                            <MenuItem value=''>
                              <div>All</div>
                            </MenuItem>
                            {clientList?.map((office) => (
                              <MenuItem value={office.label} key={office.label}>
                                {office.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item>
                          <div className='f-13 mb-4 fw-550'>Address</div>
                          <TextField
                            sx={{
                              // Reset common styles
                              width: '13vw',
                              // borderRadius: '0px',
                              // Add any additional styles you need
                            }}
                            defaultValue={''}
                            displayEmpty={true}
                            value={filters?.address}
                            onChange={(e) => {
                              setFilters((old) => {
                                return { ...old, address: e.target.value };
                              });
                            }}
                          />{' '}
                        </Grid>
                        <Grid item>
                          <div className='f-13 mb-4 fw-550'>Order Created Date</div>
                          <DateRangeFilter
                            setDate={setDate}
                            startDate={filters?.order_date_from}
                            endDate={filters?.order_date_to}
                            startDateName={'order_date_from'}
                            endDateName={'order_date_to'}
                          />
                        </Grid>{' '}
                        <Grid item>
                          <div className='f-13 mb-4 fw-550'>Participant Form Completed Date</div>
                          <DateRangeFilter
                            setDate={setDate}
                            startDate={filters?.form_completed_date_from}
                            endDate={filters?.form_completed_date_to}
                            startDateName={'form_completed_date_from'}
                            endDateName={'form_completed_date_to'}
                          />
                        </Grid>
                        <Grid item>
                          <div className='f-13 mb-4 fw-550'>Verification Completed Date</div>
                          <DateRangeFilter
                            setDate={setDate}
                            startDate={filters?.verification_completed_date_from}
                            endDate={filters?.verification_completed_date_to}
                            startDateName={'verification_completed_date_from'}
                            endDateName={'verification_completed_date_to'}
                            title={'Verification Completion Date'}
                          />
                        </Grid>{' '}
                      </Grid>
                    </Grid>
                    <Grid item sm={12} md={1} className='d-flex align-items-center'>
                      <div>
                        <CSVLink
                          data={csvData}
                          filename={getCSVName()}
                          ref={csvExport}
                          style={{ display: 'none' }}
                        ></CSVLink>
                        <Button
                          variant='contained'
                          onClick={getCSVData}
                          className={'add-btn'}
                          hideAddIcon
                        >
                          <SaveAlt style={{ marginRight: '8px', fontSize: '18px' }} />
                          {'Export'}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Box>
              <CardContent>
                <Table
                  loader={loading}
                  columns={columns}
                  data={tableData}
                  count={total}
                  current_page={currPage}
                  perPageRecord={perPageRecords}
                  setPerPageRecords={setPerPageRecords}
                  setCurrPage={setCurrPage}
                  onRowClick={onRowClick}
                  isClickable={true}
                />
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Reports;
